import React, { useEffect, useRef } from "react";

interface GoogleMapProps {
  lat: number;
  lng: number;
}

const GoogleMap: React.FC<GoogleMapProps> = ({ lat, lng }) => {
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapRef.current && lat && lng) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat, lng },
        zoom: 18,
      });

      new window.google.maps.Marker({
        position: { lat, lng },
        map,
        title: "Seçilen Konum",
      });
    }
  }, [lat, lng]);

  return <div ref={mapRef} style={{ width: '100%', height: "412px" }} />;
};

export default GoogleMap;
