import { TimeHelper } from '../../helpers/TimeHelper';
import React, { useEffect, useState } from 'react';
import '../styles/ActiveWeeklyStyle.css';
import { Repository } from '../../data/repository/repository';
import { AutomationDTO } from '@/model/dto/AutomationDTO';
import { toast } from 'react-toastify';
import EditCampaignPopup from '../popup/EditCampaignPopup';
import { Modal } from 'react-bootstrap';
import CancelAutomation from '../popup/CancelAutomation';
import StopCampaignSessionPopup from '../popup/StopCampaignSessionPopup';
import moment from 'moment';
import { CampaignDTO } from '../../model/dto/CampaignDTO';

export default function ActiveWeeklyPlanScreen() {
    const bg = require('../images/icons/Sol_color-2.png');
    const edit = require('../images/icons/Edit.png')
    const dailyStop = require('../images/icons/dailystop.png')
    const stop = require('../images/icons/stop.png')
    const currentMoment = TimeHelper.now();
    const formattedDate = currentMoment.format("D MMMM dddd YYYY");
    const currentDay = TimeHelper.now().format('dddd');

    const [automationInfo, setAutomationInfo] = useState<AutomationDTO | null>();
    const [campaignInfo, setCampaignInfo] = useState<CampaignDTO | null>();

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [openCancelAutomationModal, setOpenCancelAutomationModal] = useState<boolean>(false)
    const [showStopSessionModal, setShowStopSessionModal] = useState<boolean>(false);

    const daysOfWeek = automationInfo?.activeDays ?? [];

    const weekDays = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
    const getActiveAutomation = async () => {
        const response = await Repository.getActiveAutomation(Repository.getMyCompany()?.id ?? '')
        if (response) {
            setAutomationInfo(response)
        } else {
            toast.error('Aktif plan bulunmamaktadır')
        }
    }

    const fetchCampaignsData = async () => {
        try {
            const campaigns = await Repository.fetchMyCampaigns() ?? [];
            const activeCampaigns = campaigns.filter((obj: CampaignDTO) => {
                const startDate = moment(obj.startDate, "DD/MM/YYYY HH:mm:ss")
                const finishDate = moment(obj.finishDate, "DD/MM/YYYY HH:mm:ss")
                return obj.isActive;

            });

            const promotionTypeZeroCampaigns = activeCampaigns.filter((campaign: any) => campaign.promotionType === 0);
            if (promotionTypeZeroCampaigns.length > 0) {
                setCampaignInfo(promotionTypeZeroCampaigns[0])

            }
        } catch (error) {
            console.log(error);

        }
    }

    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
        getActiveAutomation();
        fetchCampaignsData();
    };


    const handleOpenCancelAutomationModal = () => {
        setOpenCancelAutomationModal(true)
    }
    const handleCloseCancelAutomation = () => {
        setOpenCancelAutomationModal(false)
    }


    const handleStopSessionClick = () => {
        setShowStopSessionModal(true);
    };
    const handleCloseStopSessionModal = () => {
        setShowStopSessionModal(false);
    };


    useEffect(() => {
        getActiveAutomation()
        fetchCampaignsData()
    }, [])

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2">
                        <div className="backgroundDiv">
                            <img src={bg} alt="background" style={{ height: '80vh' }} />
                        </div>
                    </div>
                    <div className="col-sm-10">
                        <div style={{ padding: '40px', height: ' calc(100vh - 280px)' }}>
                            <div className="titleLiveRightNov">
                                <div className="liveRightNov" style={{ marginLeft: '-20px' }}>
                                    <h2 style={{ fontWeight: '600', fontSize: '24px', lineHeight: '34px', paddingLeft: '20px' }}>🔥 Şu Anda Canlıdasın</h2>
                                    <h6 style={{ fontWeight: '700', color: '#F15F50' }}>Bugün {formattedDate}</h6>
                                </div>
                                <hr style={{ width: '100%' }} />
                            </div>

                            <div className="row upperContent" style={{ display: 'flex', justifyContent: 'center', maxWidth: 'unset' }}>
                                <div className="col-4 contentItem">
                                    <p className='weekly-active-plan-title'>Tanımladığın indirim oranı</p>
                                    <p className='weekly-active-plan-values'>🤩 %{campaignInfo?.discountRate} </p>


                                </div>
                                <div className="col-4 contentItem">
                                    <p className='weekly-active-plan-title'>Ne zaman başlıyor?</p>
                                    <p className='weekly-active-plan-values'>{moment(campaignInfo?.startDate, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')}</p>


                                </div>
                                <div className="col-4 contentItem">
                                    <p className='weekly-active-plan-title'>Ne zaman bitiyor?</p>
                                    <p className='weekly-active-plan-values'>{moment(campaignInfo?.finishDate, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')}</p>


                                </div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            <div>
                                <p>Hangi günler indirim açılıyor?</p>
                                <div className="week-days-container" style={{ display: 'flex', justifyContent: 'flex-start', textAlign: 'left' }}>
                                    {weekDays.map((day, index) => {
                                        const isActive = daysOfWeek.includes(index);
                                        const isToday = currentDay === day;

                                        return (
                                            <div
                                                key={day}
                                                className={`day-item ${isToday && isActive ? 'today' : isActive ? '' : 'inactive'}`}
                                            >
                                                {isToday && isActive ? `🔥 ${day} (Bugün)` : day}
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                            <hr style={{ width: '100%', marginBottom: '40px', marginTop: '40px' }} />
                            <div className="weekly-plan-buttons">
                                <button className='weekly-orange-button' onClick={handleOpenModal}> <img style={{ width: '20px' }} src={edit} /> İndirimi Düzenle</button>
                                <button className='weekly-light-button' onClick={handleStopSessionClick}><img style={{ width: '20px' }} src={dailyStop} /> {currentMoment.format("D MMMM dddd")} Durdur</button>
                                <button className='weekly-light-button' onClick={handleOpenCancelAutomationModal}><img style={{ width: '20px' }} src={stop} /> Haftalık Planı İptal Et</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal size='xl'
                    show={modalOpen}
                    onHide={handleCloseModal}
                    style={{ maxHeight: '95vh' }}
                >
                    <EditCampaignPopup onClose={handleCloseModal} campaignId={automationInfo?.id} startTime={automationInfo?.startTime} finishTime={automationInfo?.finishTime} discountRate={automationInfo?.discountRate} />
                </Modal>
            </div>
            <div>
                <Modal size='xl'
                    show={openCancelAutomationModal}
                    onHide={handleCloseCancelAutomation}
                    style={{ maxHeight: '45vh' }}
                >
                    <CancelAutomation campaignId={automationInfo?.id} onClose={handleCloseCancelAutomation} />
                </Modal>
            </div>
            <div className='stop-session-container'>
                <Modal
                    show={showStopSessionModal}
                    onHide={handleCloseStopSessionModal}
                    style={{ height: '95vh' }}
                >
                    <StopCampaignSessionPopup onClose={handleCloseStopSessionModal} automation={true} />
                </Modal>
            </div>
        </div>
    );
}
