import React, { useEffect, useState } from 'react';
import '../styles/style.css';
import CreateCampaignScreen from './CreateCampaignScreen';
import FlashOfferScreen from './FlashOfferScreen';
import StatisticsScreen from './StatisticsScreen';
import RestaurantUpdateScreen from './RestaurantUpdateScreen';
import { Button, Modal } from 'react-bootstrap';
import QRCodeConsume from '../popup/QRCodeConsume';
// import { logout } from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import ActiveCampaignScreen from './ActiveCampaignScreen';
import NotificationScreen from './NotificationScreen';
import DocumentsScreen from './DocumentsScreen';
import AboutUsScreen from './AboutUsScreen';
import TermsAndConditionsScreen from './TermsAndConditionsScreen';
import PrivacyPolicyScreen from './PrivacyPolicyScreen';

import { Repository } from '../../data/repository/repository';

import {routeLogin, RoutingHelper} from "../../constants/routes";

export default function SuperAdminHomeScreen() {

    const [currentComponent, setCurrentComponent] = useState(<NotificationScreen />);
    const [companyName, setCompanyName] = useState<string | null>();
    const [activeButton, setActiveButton] = useState('notifications');

    const logo = require('../images/yerimLogo.png');
    const notification = require('../images/icons/Notification.png')
    const logOut = require('../images/icons/Logout.png')
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const qr = require('../images/icons/Qr-code.png')


    
    
    const fetchData = async (companyId:String) => {
        try {
            if (companyId) {
                const data = await Repository.getCompany(companyId);
                setCompanyName(data?.name);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(Repository.getMyCompany()?.id ?? '');
    }, []);



    useEffect(() => {
        if(!Repository.hasValidSession() || Repository.getMyCompany()?.userRole !== 0) {
            RoutingHelper.navigateByUrl(routeLogin)
        }
    }, []);

    const handleComponentChange = (component: any, buttonName:string) => {
        setCurrentComponent(component);
        setActiveButton(buttonName)
    };
    const handleLogout = async () => {
        await Repository.logOut()
        RoutingHelper.navigateByUrl(routeLogin)
    };
    const handleShowLogoutPopup = () => {
        setShowLogoutPopup(true);
    };
    const handleCancelLogout = () => {
        setShowLogoutPopup(false);
    };
    const logoutConfirmationModal = (
        <div >
            <Modal className='logout-popup' show={showLogoutPopup} onHide={() => setShowLogoutPopup(false)}>
                <Modal.Header>
                    <Modal.Title>Çıkış Yapıyorsunuz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tekrar panele erişebilmek için şifre ile giriş yapmanız gerekecektir. Devam etmek istiyor musunuz?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowLogoutPopup(false)}>
                        Vazgeç
                    </Button>
                    <Button variant="primary" onClick={handleLogout}>
                        Evet
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="horizontal-container">
                        <div className="horizontal-div upper-div">
                            <div className="navbar">
                                <div className="navbar-logo">
                                    <img src={logo} alt='logo' />
                                </div>
                                <ul className="navbar-links">
                                    <li><button className={`navbar-components-first-button ${activeButton === 'notifications' ? 'active' : ''}`} onClick={() => handleComponentChange(<NotificationScreen />, 'notifications')}>Bildirimler</button></li>
                                    <li><button className={`navbar-component-buttons ${activeButton === 'documents' ? 'active' : ''}`}  onClick={() => handleComponentChange(<DocumentsScreen />,'documents')}>Dökümanlar</button></li>
                                </ul>
                                <ul className='navbar-right'>
                                    <li>
                                        <button onClick={handleShowLogoutPopup} className='companyButton'><img style={{ width: '15px', marginRight: '5px' }} src={logOut} /> Çıkış</button>

                                        {logoutConfirmationModal}


                                    </li>
                                </ul>
                                <div className='companyTitle ' style={{ display: 'block' }}>
                                    <h2>Merhaba {companyName}! 😎</h2>
                                    <p style={{ fontWeight: '100' }}>Yerim'e Tekrar Hoşgeldin!</p>
                                </div>
                            </div>
                        </div>
                        <div className="horizontal-div second-div">
                            <div className="centered-div">
                                <div className="overlay-div">
                                    {currentComponent}

                                    <div className='bottomLinks'>
                                        <button onClick={() => { setCurrentComponent(<AboutUsScreen />) }}>Hakkımızda</button>
                                        <button onClick={() => { setCurrentComponent(<TermsAndConditionsScreen />) }}>Şartlar ve Koşullar</button>
                                        <button onClick={() => { setCurrentComponent(<PrivacyPolicyScreen />) }}>Gizlilik Politikası</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
