import React, { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { ButtonBase, MenuItem } from '@mui/material';
import { BsChevronDown, BsInfoCircle } from 'react-icons/bs';
import { BsFillCaretDownFill } from 'react-icons/bs';
import '../styles/RegisterPage.css'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DistrictDTO } from '../../model/dto/DistrictDTO';
import { NeighborhoodDTO } from '../../model/dto/NeighborhoodDTO';
import { CompanyDelegate, CreateCompanyRequest, CreateCompanyRequestFactory, TaxCertificate } from '../../model/request/CreateCompanyRequest';

import { CityDTO } from '../../model/dto/CityDTO';
import { Address } from '../../model/common/Address';
import { Location } from '../../model/common/Location';
import { Box, Checkbox, Grid, InputAdornment, TextField, Typography, styled } from '@mui/material';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { cdnBaseUrl } from '../../api/baseUrl';
import { CreateCompanyRequestValidator, CompanyDelegateValidator, TaxCertificateValidator } from '../../model/request/CreateCompanyRequest';
import { LocationValidator } from '../../model/common/Location';
import { AddressValidator } from '../../model/common/Address';
import { Validator } from 'fluentvalidation-ts';
import { MdAccountCircle } from 'react-icons/md';
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import GoogleMap from './GoogleMap';
import AgreementScreen from './AgreementScreen';
import { Repository } from '../../data/repository/repository';
import { StreetDTO } from '../../model/dto/StreetDTO';
import { DocumentDTO } from '../../model/dto/DocumentDTO';
import { ImageDTO } from '../../model/dto/ImageDTO';
import { routeLogin, RoutingHelper } from "../../constants/routes";
import { Constants } from '../../constants/constants';
import { dedupedFunction } from '../../utilities/dedupedFunction';


interface Props {
  onClose: () => void
}

const OrangeCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    fontSize: '32px',
    marginRight: '10px'
  },
  '@media (max-width: 480px)': {
    fontSize: '20px',
  },
  color: '#1D1E2599',
  '&.Mui-checked': {
    color: 'orange',
  },
});



const RegisterScreen: React.FC = () => {
  const enterpriseIcon = require('../images/icons/Enterprise.png');
  const noodleIcon = require('../images/icons/Noodle-bowl.png')
  const userIcon = require('../images/icons/User.png')
  const phoneIcon = require('../images/icons/Phone.png')
  const versionIcon = require('../images/icons/Version.png')
  const timerIcon = require('../images/icons/Timer.png')
  const mobileAddIcon = require('../images/icons/Mobile--add.png')
  const cameraActionIcon = require('../images/icons/Camera--action.png')
  const TRIcon = require('../images/icons/TR.png')
  const lockIcon = require('../images/icons/Locked.png')
  const emailIcon = require('../images/icons/Email--new.png')
  const keyIcon = require('../images/icons/Password.png')
  const pdfIcon = require('../images/icons/pdfIcon.png')
  const uploadIcon = require('../images/icons/Cloud--upload.png')
  const arrow = require('../images/icons/Text--new-line.png')
  const yerimLogo = require('../images/yerimLogo.png')
  const referanceIcon = require('../images/icons/Order-details.png')

  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const [cityName, setCityName] = useState<string>('');
  const [districts, setDistricts] = useState<DistrictDTO[]>([]);
  const [streets, setStreets] = useState<StreetDTO[]>([]);
  const [streetName, setStreetName] = useState<string>('')
  // const [selectedCityId, setSelectedCityId] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [neighborhoodId, setNeighborhoodId] = useState('');
  const [neighborhoods, setNeighborhoods] = useState<NeighborhoodDTO[]>([])
  const [longitude, setLongitude] = useState<string>('28.9784');
  const [latitude, setLatitude] = useState<string>('41.0082');
  const [cities, setCities] = useState<CityDTO[]>([]);
  const [selectedCity, setSelectedCity] = useState<CityDTO | null>({
    id: "66a775e526834722b02a5a58",
    name: "İSTANBUL"
  });

  const [companyLogoUrl, setCompanyLogoUrl] = useState<ImageDTO | null>();
  const [companyPhotoUrl, setCompanyPhotoUrl] = useState<ImageDTO | null>();
  const navigate = useNavigate();
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [formData, setFormData] = useState<CreateCompanyRequest | null>(CreateCompanyRequestFactory.build(Repository.getMyCompany()));
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [contractUrl1, setContractUrl1] = useState<boolean>(false);
  const [contractUrl2, setContractUrl2] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [submitTried, setSubmitTried] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [documentAboutUsUrl, setDocumentAboutUsUrl] = useState<string>('');
  const [documentPrivacyUrl, setDocumentPrivacyUrl] = useState<string>('');
  const [documentTermsAndConditionsUrl, setDocumentTermsAndConditionsUrl] = useState<string>('');


  const [openAgreementUrl, setOpenAgreementUrl] = useState(false)
  const [agreementText, setAgreementText] = useState<string>('');


  const agreementUrl = require('../texts/agreement.txt');

  const date = new Date();
  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const [companyInfoValidationResult, setCompanyInfoValidationResult] = useState<any>();
  const [locationInfoValidationResult, setLocationValidationResult] = useState<any>();
  const [addressInfoValidationResult, setAddressInfoValidationResult] = useState<any>();
  const [delegateInfoValidationResult, setDelegateInfoValidationResult] = useState<any>();

  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);


  type DeepClone<T> = T extends object ? {
    [K in keyof T]: DeepClone<T[K]> } : T;
  function deepCopy<T>(obj: T): DeepClone<T> {
    return JSON.parse(JSON.stringify(obj));
  }

  useEffect(() => {
    if (cities && formData?.address?.city) {
      const city = cities.find(city => city.name == formData.address.city)
      setSelectedCity(city ?? {
        id: "66a775e526834722b02a5a58",
        name: "İSTANBUL"
      });
    }
  }, [cities]);

  useEffect(() => {
    if (documents !== null && documents.length > 0) {
      setDocumentTermsAndConditionsUrl(getDocumentUrlByCode('LO1'));
      setDocumentAboutUsUrl(getDocumentUrlByCode('LO0'));
      setDocumentPrivacyUrl(getDocumentUrlByCode('LO3'));
    }
  }, [documents]);



  const getDocumentUrlByCode = (code: string) => {
    try {
      var document = documents.filter(d => d.code === code)[0];

      return cdnBaseUrl + document.documentUrl;
    }
    catch (error) {
      return "";
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const ourData = deepCopy(formData!);
    if (e.target.name === 'name') {
      ourData.name = e.target.value
    }
    else if (e.target.name === 'referanceCode') {
      ourData.referenceCode = e.target.value
    }
    else if (e.target.name === 'type') {
      ourData.type = e.target.value
    }
    else if (e.target.name === 'delegateName') {

      const numberRegex = /\d/;

      if (numberRegex.test(e.target.value)) {
        return;
      }

      ourData.delegate.delegateName = e.target.value
    }
    else if (e.target.name === 'delegateSurname') {

      const numberRegex = /\d/;

      if (numberRegex.test(e.target.value)) {
        return;
      }

      ourData.delegate.delegateSurname = e.target.value
    }
    else if (e.target.name === 'delegatePosition') {
      ourData.delegate.delegatePosition = e.target.value
    }
    else if (e.target.name === 'ownerPhoneNumber') {

      const numberRegex = /^(?:\d+|\s*)$/;

      if (!numberRegex.test(e.target.value)) {
        return;
      }

      ourData.ownerPhoneNumber = e.target.value
    }
    else if (e.target.name === 'phoneNumber') {

      const numberRegex = /^(?:\d+|\s*)$/;

      if (!numberRegex.test(e.target.value)) {
        return;
      }

      ourData.phoneNumber = e.target.value
    }
    else if (e.target.name === 'workingHours') {
      ourData.workingHours = e.target.value
    }
    else if (e.target.name === 'capacity') {
      const numericValue = value.replace(/[^0-9]/g, '')
      if (e.target.value === '') {
        ourData.capacity = null
      }
      else {
        ourData.capacity = Number(numericValue);
      }
    }
    else if (e.target.name === 'street') {
      ourData.address.street = e.target.value
    }
    else if (e.target.name === 'buildingNo') {
      ourData.address.buildingNo = e.target.value
    }
    else if (e.target.name === 'webSiteLink') {
      ourData.webSiteLink = e.target.value
    }
    else if (e.target.name === 'email') {
      ourData.email = handleEmailChange(value)

    } else if (e.target.name === 'tradeName') {
      ourData.taxCertificate.tradeName = e.target.value

    } else if (e.target.name === 'taxOffice') {
      ourData.taxCertificate.taxOffice = e.target.value

    } else if (e.target.name === 'taxNo') {

      const numberRegex = /^(?:\d+|\s*)$/;

      if (!numberRegex.test(e.target.value)) {
        return;
      }

      ourData.taxCertificate.taxNo = e.target.value

    } else if (e.target.name === 'taxPayerName') {

      const numberRegex = /\d/;

      if (numberRegex.test(e.target.value)) {
        return;
      }

      ourData.taxCertificate.taxPayerName = e.target.value

    } else if (e.target.name === 'identificationNumber') {
      var numberRegex = /^(?:\d+|\s*)$/;

      if (!numberRegex.test(e.target.value)) {
        return;
      }

      ourData.taxCertificate.identificationNumber = e.target.value
    }


    ourData.labels = ['']

    setFormData(ourData)


  };


  const getCoordinatesFromString = (input: string): string[] => {

    try {
      //Expected input values: "38.67856314285213, 35.521882515343414", "(38.67856314285213, 35.521882515343414)"
      //Sadece sayılar, nokta ve virgül karakterlerini tutan bir regex ifadesi kullanarak filtre yap
      var filteredString = input.replace(/[^0-9.,]/g, '');
      return filteredString.split(',');
    }
    catch (error) {
      return [];
    }
  }

  const handleEmailChange = (value: string): string => {
    const firstChar = value.charAt(0).replace('İ', 'i').toLowerCase()
    return firstChar + value.slice(1);
  };

  const formatTime = (time: string) => {
    if (!time) return '';
    const [hour, minute] = time.split(':');
    return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  };



  const hoursOptions = Array.from({ length: 24 }, (_, i) => `${i}:00`);

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const startTimeFormatted = formatTime(event.target.value);
    setStartTime(startTimeFormatted);

    const updatedFormData = deepCopy(formData!);
    updatedFormData.workingHours = `${startTimeFormatted} - ${endTime}`;
    setFormData(updatedFormData);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const endTimeFormatted = formatTime(event.target.value);
    setEndTime(endTimeFormatted);

    const updatedFormData = deepCopy(formData!);
    updatedFormData.workingHours = `${startTime} - ${endTimeFormatted}`;
    setFormData(updatedFormData);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const temp = deepCopy(formData!)

    temp.password = e.target.value

    setFormData(temp);

    if (temp.password !== confirmPassword) {
      setErrorMessage('Şifreler eşleşmiyor');
    } else {
      setErrorMessage('');
    }
  };


  const handlePasswordConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const temp = formData!;

    setErrorMessage('');

    if (temp.password !== value) {
      setErrorMessage('Şifreler eşleşmiyor');
    } else {
      setErrorMessage('');
    }

    setConfirmPassword(value);
  };

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const handleSegmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    let updatedSegments = [...selectedSegments];

    if (updatedSegments.length < 3 && !updatedSegments.includes(selectedOption)) {
      updatedSegments.push(selectedOption)
    }
    else if (updatedSegments.length === 3 && !updatedSegments.includes(selectedOption)) {
      toast.error("En fazla üç adet segment seçebilirsiniz")
    }

    setSelectedSegments(updatedSegments);

    const updatedFormData = deepCopy(formData!);
    updatedFormData.segments = updatedSegments;
    setFormData(updatedFormData);
  };

  const handleRemoveSegment = (segment: string) => {
    const updatedSegments = selectedSegments.filter((item) => item !== segment);
    setSelectedSegments(updatedSegments);
  };



  const handleContractUpload = async (e: React.ChangeEvent<HTMLInputElement>, contractNumber: string) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      try {
        const contractUrl = await Repository.uploadDocument(file);
        const contract1 = formData!
        const contract2 = formData!
        if (file.name === 'contractUrl1')
          contract1.contractUrl1 = e.target.value
        setFormData(contract1)
        setContractUrl1(true)
        if (file.name === 'contractUrl2')
          contract2.contractUrl2 = e.target.value
        setFormData(contract2)
        setContractUrl2(true)
      } catch (error) {
        console.error(`Contract upload failed:`, error);
      }
    }
  };

  const handleLogoUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      try {
        const uploadResultDTO = await Repository.uploadFile(file) ?? null;
        // const getImageLogoUrl = await getImages(imageLogoUrl)
        setCompanyLogoUrl(uploadResultDTO);

        const updatedFormData = deepCopy(formData!);
        updatedFormData.logoUrl = uploadResultDTO?.url ?? '';

        setFormData(updatedFormData);
      } catch (error) {
        console.error('Logo upload failed:', error);
      }
    }
  };


  const handleCompanyPhotos = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      try {
        const uploadedPhotoUrl = await Repository.uploadFile(file);

        // const getUploadedPhoto = await getImages(uploadedPhotoUrl)
        setCompanyPhotoUrl(uploadedPhotoUrl);

        const updatedFormData = deepCopy(formData!);
        updatedFormData.photoUrl = uploadedPhotoUrl?.url ?? '';

        setFormData(updatedFormData);
      } catch (error) {
        console.error('Photo upload failed:', error);
      }
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  function validateData<T>(validator: Validator<T>, validationGroup: string, data: T): any {

    const validateResult: any = validator.validate(data);

    let errorCount = Object.keys(validateResult).length;
    return validateResult
    // const validationUIElements = document.querySelectorAll('[data-fluent-validation-key][data-fluent-validation-group="' + validationGroup + '"]');

    // validationUIElements.forEach(element => {
    //   if (element instanceof TextField) {

    //     const validationKey = element.getAttribute('data-fluent-validation-key');

    //     if (validationKey && validateResult[validationKey]) {
    //       element.style.display = 'inline-block';
    //       element.textContent = validateResult[validationKey];
    //     }
    //     else {
    //       element.style.display = 'none';
    //     }
    //   }
    // });

    // if (errorCount == 0) {
    //   return true;
    // }

    // return false;
  }

  const validateFormData = () => {
    if (!formData) {
      return false;
    }
    var createCompanyRequestValidationResult = validateData(new CreateCompanyRequestValidator(), 'create-company-request', formData!)
    var locationValidationResult = validateData(new LocationValidator(), 'location', formData!.location)
    var addressValidationResult = validateData(new AddressValidator(), 'address', formData!.address)
    var companyDelegateValidationResult = validateData(new CompanyDelegateValidator(), 'company-delegate', formData!.delegate)
    var taxCertificateValidationResult = validateData(new TaxCertificateValidator(), 'tax-certificate', formData!.taxCertificate)

    setCompanyInfoValidationResult(createCompanyRequestValidationResult)
    setLocationValidationResult(locationValidationResult)
    setAddressInfoValidationResult(addressValidationResult)
    setDelegateInfoValidationResult(companyDelegateValidationResult)

    if (Object.keys(createCompanyRequestValidationResult).length > 0 || Object.keys(locationValidationResult).length > 0 || Object.keys(addressValidationResult).length > 0 || Object.keys(companyDelegateValidationResult).length > 0 || Object.keys(taxCertificateValidationResult).length > 0) {
      return false;
    }

    return true;
  }


  const fetchGuestToken = async () => {
    console.warn('fetch guest token')

    const guestToken = await Repository.loginAsGuest();
    //Repository.loginAsGuest();
    //await ApiService.createAndLoginGuest();
    if (!guestToken) {
      toast.error('Bir hata meydana geldi. Lütfen tekrar deneyin.');
      return;
    }
    //const result = await Repository.loginAsGuest()
    //console.info(`guest login result ${result}`)
  }




  async function prepearingAgreement() {
    const ourData = deepCopy(formData!);

    try {

      const response = await fetch(agreementUrl);
      let agreementText = await response.text();


      agreementText = agreementText
        .replaceAll('##city##', ourData.address.city || '')
        .replaceAll('##district##', ourData.address.district || '')
        .replaceAll('##name##', ourData.taxCertificate.tradeName || ourData.name || '')
        .replaceAll('##neighborhood##', ourData.address.neighborhood || '')
        .replaceAll('##street##', ourData.address.street || '')
        .replaceAll('##buildingNo##', ourData.address.buildingNo || '')
        .replaceAll('##date##', formatDate(date))
        .replaceAll('##email##', ourData.email || '');

      agreementText = `<p>${agreementText.replace(/\n\n/g, '</p><p>').replace(/\n/g, '<br/>')}</p>`;
      setAgreementText(agreementText)
    } catch (error: any) {
      console.log(error);

    }

  }


  useEffect(() => {
    if (formData && date) {
      prepearingAgreement();
    }
  }, [formData, date]);


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!formData?.address?.city) {
      const temp = deepCopy(formData);
      if (temp && temp.address) {
        temp.address.city = 'İSTANBUL';
        setFormData(temp);
      }
    }


    setSubmitTried(true);
    var isValid = validateFormData();

    if (isValid === false) {
      toast.error('Lütfen gerekli alanları doldurunuz')
      return;
    }

    if (!checkboxChecked) {
      setShowWarning(true);
      return;
    }
    setShowWarning(false);


    const ourData = deepCopy(formData!);
    const { password } = ourData;

    ourData.labels = [...ourData.segments ?? []]



    try {
      const responseData = await Repository.registerCompany(ourData);
      if (responseData && responseData !== null) {
        console.log('Şirket kaydı başarıyla tamamlandı:', responseData);
        toast.success('Kayıt başarıyla tamamlandı! Giriş Yapabilirsiniz.');
        const success = await Repository.postCompanyAgreement(agreementText, responseData?.id ?? '');
        setTimeout(() => {
          console.log('redirecting to the login page..')
          RoutingHelper.navigateByUrl(routeLogin)
        }, 3000)
      }

      // localStorage.setItem('companyId', responseData.id)
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        const responseError = error.response.data;
        if (responseError.code === 'CompanyServiceError.EmailAlreadyExistError' &&
          responseError.message === 'Email already exist.') {
          toast.error('Bu email daha önceden kayıt edilmiş. Lütfen farklı bir email ile kayıt oluşturun.');
        } else {
          toast.error('Bir hata meydana geldi lütfen tekrar deneyiniz.');
        }
      } else {
        console.error('Şirket kaydı sırasında bir hata oluştu:', error);
        toast.error('Bir hata meydana geldi lütfen tekrar deneyiniz.');
      }
    }
    if (password !== confirmPassword) {
      setErrorMessage('Parolalar eşleşmiyor');
      return;
    }

    if (!isValidPassword(password)) {
      setErrorMessage('Lütfen uygun bir parola girin');
      return;
    }
    setErrorMessage('');
    console.log('Parola doğrulandı ve işlem yapılabilir.');


  };


  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const decimalRegex = /^\d+(\.\d{1,2})?$/;

    if (!decimalRegex.test(value)) {
      var coordinates = getCoordinatesFromString(value);

      if (coordinates.length === 0 || coordinates.includes('')) {
        return;
      }

      const ourData = deepCopy(formData!);
      ourData.location.latitude = Number(coordinates[0]);
      ourData.location.longitude = Number(coordinates[1]);

      setLatitude(coordinates[0]);
      setLongitude(coordinates[1]);

      setFormData(ourData);

      return;
    }


    if (latLng) {
      const ourData = deepCopy(formData!);

      if (name === 'longitude') {
        ourData.location.longitude = latLng.lng;
        setLongitude(String(latLng.lng));
      } else if (name === 'latitude') {
        ourData.location.latitude = latLng.lat;
        setLatitude(String(latLng.lat));
      }

      setFormData(ourData);
    } else {
      const ourData = deepCopy(formData!);

      if (name === 'longitude') {
        ourData.location.longitude = Number(value);
        setLongitude(value);
      } else if (name === 'latitude') {
        ourData.location.latitude = Number(value);
        setLatitude(value);
      }

      setFormData(ourData);
    }




  };


  const isValidPassword = (password: string) => {
    const regex = /^.{6,}$/;
    console.log(regex.test(password));

    return regex.test(password);
  };


  const handleCityChange = (event: any) => {

    if (selectedCity?.id === "66a775e526834722b02a5a58" && selectedCity.name === "İSTANBUL") {
      const temp = deepCopy(formData!);
      temp.address.city = 'İSTANBUL';
      temp.address.district = '';
      temp.address.neighborhood = '';
      temp.address.street = '';
      setFormData(temp);
      setDistrictId('');
      setNeighborhoodId('');
      setStreetName('');
    }



    const selectedName = event.target.value;
    const city = cities.find(city => city.name == selectedName)
    if (city !== null) {
      setSelectedCity(city ?? {
        id: "66a775e526834722b02a5a58",
        name: "İSTANBUL"
      })
      fetchProvinceInfo(city?.id ?? '66a775e526834722b02a5a58')

      const temp = deepCopy(formData!);
      temp.address.city = city?.name ?? 'İSTANBUL'
      setFormData(temp)
    }


  };

  const handleDistrictChange = (event: any) => {
    const selectedId = event.target.value;
    const district = districts.find(x => x.id == selectedId)
    const selectedDistrict = district?.name

    setDistrictId(selectedId);
    // requestBody.address.district = String(district)

    const districtName = deepCopy(formData!);
    if (selectedDistrict) {
      districtName.address.district = selectedDistrict
      fetchNeighboorhoodInfo(selectedId)
      setFormData(districtName)
    }


  };

  const handleNeighborhoodChange = (event: any) => {
    const selectedId = event.target.value;
    const neighborhood = neighborhoods.find(x => x.id == selectedId)
    const selectedNeighborhood = neighborhood?.name
    console.log(selectedId);

    setNeighborhoodId(selectedId)

    const neighborhoodName = deepCopy(formData!);
    if (selectedNeighborhood) {
      neighborhoodName.address.neighborhood = selectedNeighborhood
      fetchStreetInfo(selectedId)
      setFormData(neighborhoodName)
    }
  };

  const handleStreetChange = (event: any) => {
    const selectedStreet = event.target.value;
    setStreetName(selectedStreet)


    const streets = deepCopy(formData!);
    streets.address.street = selectedStreet;
    setFormData(streets)
  }




  const fetchProvinceInfo = async (selectedId: string) => {
    try {
      const response = await Repository.getDistrictById(selectedId);

      if (response) {
        // console.log(response);

        const districtList = response.map((district: DistrictDTO) => district);
        setDistricts(districtList);

      } else {
        console.error('API\'den ilçe bilgileri alınamadı.');
      }
    } catch (error) {
      console.error('API\'ye bağlanırken bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    fetchProvinceInfo('66a775e526834722b02a5a58')
  }, [])


  const fetchNeighboorhoodInfo = async (selectedId: string) => {
    try {
      const response = await Repository.getNeighboorhoodDistrictById(selectedId)
      if (response) {
        const neighborhoodList = response.map((neighborhood: NeighborhoodDTO) => neighborhood)
        setNeighborhoods(neighborhoodList)
      }



    } catch (error) {
      console.log(error);
    }
  }

  const fetchStreetInfo = async (selectedId: string) => {
    try {
      const response = await Repository.getStreetsById(selectedId) ?? []

      const streetList = response.map((street: StreetDTO) => street)
      setStreets(streetList)
    } catch (error) {
      console.log(error);

    }
  }
  const handleAgreementOpen = () => { setOpenAgreementUrl(true) }
  const handleAgreementClose = () => { setOpenAgreementUrl(false) }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(e.target.checked);
  };

  function test(address: string) {
    fromAddress(address)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        console.log(lat, lng);
        if (lat !== formData?.location.latitude || lng !== formData?.location.longitude) {
          console.log(lat, lng);
          setLatitude(String(lat));
          setLongitude(String(lng));
          setLatLng({ lat, lng });
  
          const updatedFormData = deepCopy(formData!);
          updatedFormData.location.latitude = lat;
          updatedFormData.location.longitude = lng;
          setFormData(updatedFormData);
        }
      })
      .catch(console.error);
  }


  function geoCodeAddress() {
    if (formData?.address.city === null) return
    if (formData?.address.district === null) return
    if (formData?.address.neighborhood === null) return
    if (formData?.address.street === null) return
    if (formData?.address.buildingNo === null) return
    const address = `${formData?.address.street} ${formData?.address.buildingNo}, ${formData?.address.neighborhood}, ${formData?.address.district}, ${formData?.address.city}`;
    console.log(address);
    test(address);
  }

  const debouncedGeoCodeAddress = useCallback(
    dedupedFunction(geoCodeAddress, 300),
    [formData]
  );
  
  useEffect(() => {
    debouncedGeoCodeAddress();
  }, [formData?.address.buildingNo]);

  useEffect(() => {
    const initial = {} as CreateCompanyRequest
    initial.delegate = {} as CompanyDelegate
    initial.address = {} as Address
    initial.location = {} as Location
    initial.taxCertificate = {} as TaxCertificate
    setFormData(initial)
    setKey('AIzaSyCC4PLX2UOWJdVSyABDAJbTnme_jnoHn40')

  }, [])

  useEffect(() => {
    if (formData !== undefined && submitTried === true) {
      validateFormData();
    }

  }, [formData]);

  useEffect(() => {
    fetchGuestToken()
    loadInitialData()
  }, [])


  async function loadInitialData() {
    const guestLoginResult = await Repository.loginAsGuest();

    if (!guestLoginResult) {
      toast.error('Bir hata meydana geldi. Lütfen tekrar deneyin.');
      return;
    }

    const documents = await Repository.getAppDocuments()
    setDocuments(documents ?? [])

    const cities = await Repository.getCities()
    setCities(cities ?? [])
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8} className='register-container-col' >
          <div className='logoBackgroundDivRegister'>
            <a href='/'>
              <img className='yerimLogoRegister' src={yerimLogo} alt='Yerim Logo' />
            </a>
            <h3 className='RegisterTitle'>😃 Merhaba! Restoran Kayıt Alanına Hoş Geldiniz </h3>
            <p className='RegisterSubtitle'>Lütfen alt kısımda yer alan bilgileri eksiksiz doldurun.</p>
          </div>
          <Form>

            <div className='companyRegisterForm referans-code-section'>
              <h6 className="companyRegisterTitle">Referans Kodu</h6>
              <Box display="flex" justifyContent="center" alignItems="center">
                <TextField
                  data-fluent-validation-key='referanceCode' data-fluent-validation-group='create-company-request'
                  placeholder='Referans Kodu'
                  variant="outlined"
                  fullWidth
                  name="referanceCode"
                  style={{ marginBottom: '24px' }}
                  value={formData?.referenceCode}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={referanceIcon} />
                      </InputAdornment>
                    ),
                    style: { backgroundColor: "#FFFF" }
                  }}
                />

              </Box>

            </div>

            <div className='companyRegisterForm'>
              <h6 className="companyRegisterTitle">Firma Üyelik Bilgileri</h6>

              <Form.Group controlId="inputArea">

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Firma İsmi"
                    placeholder='Firma İsmi'
                    variant="outlined"
                    fullWidth
                    required
                    name="name"
                    style={{ marginBottom: '24px' }}
                    value={formData?.name}
                    onChange={handleChange}
                    error={companyInfoValidationResult?.name?.length > 0}
                    helperText={companyInfoValidationResult?.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={enterpriseIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>

                {/* <div className="invalid-feedback" data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'></div>

                <div className="input-container">

                  <img className='icons' src={enterpriseIcon} />
                  <Form.Control
                    type="text"
                    placeholder={'Firma İsmi*'}
                    name="name"
                    value={formData?.name}
                    onChange={handleChange}
                    required
                  />

                </div> */}

              </Form.Group>
              <Form.Group controlId="inputArea">
                <div className="invalid-feedback" data-fluent-validation-key='type' data-fluent-validation-group='create-company-request'></div>
                <div className="input-container">
                  <img className='icons' src={noodleIcon} />
                  <div className='select-container' style={{ marginBottom: '54px' }}>

                    <Form.Control
                      id='select-box'
                      style={{ height: '60px' }}
                      as='select'
                      placeholder='Firma Türü*'
                      name="type"
                      value={formData?.type}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled hidden>Firma Türü</option>
                      {Constants.COMPANY_TYPE_OPTIONS.map((option) => (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                    <BsFillCaretDownFill style={{ width: '13px', zIndex: '999' }} className="dropdown-icon" />
                  </div>
                </div>
                <p style={{ marginTop: '-25px', fontSize: '12px', color: '#d23333' }}>
                  {companyInfoValidationResult?.type?.length > 0 ? `${companyInfoValidationResult?.type}` : ''}
                </p>
              </Form.Group>

              <Form.Group controlId="inputArea">
                <div className="invalid-feedback" data-fluent-validation-key='segments' data-fluent-validation-group='create-company-request'></div>
                <div className="input-container">
                  <img className='icons' style={{ zIndex: '999' }} src={noodleIcon} />
                  <div className='segments-container'>
                    <div style={{ marginBottom: '38px' }}
                      className={`dropdown ${isOpen ? 'open' : ''} select-container`}
                    // onClick={toggleDropdown}
                    >
                      <select
                        id='select-box'
                        style={{ color: '#1D1E25', fontWeight: '300', appearance: 'none', height: '60px' }}
                        name="segment"
                        value={''}
                        onChange={handleSegmentChange}
                        required
                      >
                        <option value=''>{selectedSegments.length === 0 ? 'Firma Segmenti' : selectedSegments.join(', ')}</option>
                        {Constants.COMPANY_SEGMENT_OPTIONS.map((option) => (
                          <option key={option.value} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <BsFillCaretDownFill style={{ width: '13px', zIndex: '999' }} className="dropdown-icon" />
                    </div>
                  </div>
                </div>
                <div className="selected-segments">
                  {selectedSegments.map((segment) => (
                    <span key={segment} className="selected-segment"
                      style={{ display: 'inline-block', margin: '4px 0', marginBottom: '15px', marginRight: '8px' }}>
                      {segment}
                      <button
                        type="button"
                        className="remove-segment-button"
                        onClick={() => handleRemoveSegment(segment)}
                      >
                        &times;
                      </button>
                    </span>
                  ))}
                </div>
                <p style={{ marginTop: '-8px', fontSize: '12px', color: '#d23333' }}>
                  {companyInfoValidationResult?.segments?.length > 0 ? `${companyInfoValidationResult?.segments}` : ''}
                </p>
              </Form.Group>

              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='delegateName' data-fluent-validation-group='company-delegate'></div>
                <div className="input-container">
                  <img className='icons' src={userIcon} />
                  <Form.Control
                    type="text"
                    placeholder='Firma Temsilcisi Adı*'
                    name="delegateName"
                    value={formData?.delegate?.delegateName !== undefined ? formData?.delegate?.delegateName : ''}
                    onChange={handleChange}
                    required
                  />
                </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Firma Temsilcisi Adı"
                    required
                    placeholder='Firma Temsilcisi Adı'
                    variant="outlined"
                    name="delegateName"
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value={formData?.delegate?.delegateName !== undefined ? formData?.delegate?.delegateName : ''}
                    onChange={handleChange}
                    error={delegateInfoValidationResult?.delegateName?.length > 0}
                    helperText={delegateInfoValidationResult?.delegateName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={userIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>
              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='delegateSurname' data-fluent-validation-group='company-delegate'></div>
                <div className="input-container">
                  <img className='icons' src={userIcon} />
                  <Form.Control
                    type="text"
                    placeholder='Firma Temsilcisi Soyadı*'
                    name="delegateSurname"
                    value={formData?.delegate?.delegateSurname !== undefined ? formData?.delegate?.delegateSurname : ''}
                    onChange={handleChange}
                    required
                  />
                </div> */}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Firma Temsilcisi Soyadı"
                    required
                    placeholder='Firma Temsilcisi Soyadı'
                    variant="outlined"
                    name="delegateSurname"
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value={formData?.delegate?.delegateSurname !== undefined ? formData?.delegate?.delegateSurname : ''}
                    onChange={handleChange}
                    error={delegateInfoValidationResult?.delegateSurname?.length > 0}
                    helperText={delegateInfoValidationResult?.delegateSurname}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={userIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>

              </Form.Group>
              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='delegatePosition' data-fluent-validation-group='company-delegate'></div>
                <div className="input-container">
                  <img className='icons' src={userIcon} />
                  <Form.Control
                    type="text"
                    placeholder='Firma Temsilcisi Pozisyonu'
                    name="delegatePosition"
                    value={formData?.delegate?.delegatePosition}
                    onChange={handleChange}
                  />
                </div> */}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Firma Temsilcisi Pozisyonu"
                    placeholder='Firma Temsilcisi Pozisyonu'
                    name="delegatePosition"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value={formData?.delegate?.delegatePosition}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={userIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>

              </Form.Group>
              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='phoneNumber' data-fluent-validation-group='create-company-request'></div>
                <div className="input-container">
                  <img className='icons' src={phoneIcon} />
                  <Form.Control
                    type="tel"
                    placeholder='İşletme Sabit Telefon Numarası'
                    name="phoneNumber"
                    value={formData?.phoneNumber !== undefined ? formData?.phoneNumber : ''}
                    onChange={handleChange}
                  />
                </div> */}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="İşletme Sabit Telefon Numarası"
                    placeholder='İşletme Sabit Telefon Numarası'
                    variant="outlined"
                    name="phoneNumber"
                    fullWidth
                    type='tel'
                    style={{ marginBottom: '24px' }}
                    value={formData?.phoneNumber !== undefined ? formData?.phoneNumber : ''}
                    onChange={handleChange}

                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={phoneIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>
              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='ownerPhoneNumber' data-fluent-validation-group='create-company-request'></div>
                <div className="input-container">
                  <img className='icons' src={mobileAddIcon} />
                  <Form.Control
                    type="tel"
                    placeholder='İşletme Cep Telefonu Numarası*'
                    name="ownerPhoneNumber"
                    value={formData?.ownerPhoneNumber !== undefined ? formData?.ownerPhoneNumber : ''}
                    onChange={handleChange}
                    required
                  />
                </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="İşletme Cep Telefonu Numarası"
                    error={companyInfoValidationResult?.ownerPhoneNumber?.length > 0}
                    helperText={companyInfoValidationResult?.ownerPhoneNumber}
                    required
                    placeholder='İşletme Cep Telefonu Numarası'
                    variant="outlined"
                    name="ownerPhoneNumber"
                    fullWidth
                    type='tel'
                    style={{ marginBottom: '24px' }}
                    value={formData?.ownerPhoneNumber !== undefined ? formData?.ownerPhoneNumber : ''}
                    onChange={handleChange}

                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={mobileAddIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>
              <Form.Group style={{ width: '90%' }} controlId="inputArea">
                <div className="invalid-feedback" data-fluent-validation-key='workingHours' data-fluent-validation-group='create-company-request'></div>
                <div>
                  <div className='offer-3-buttons-container' style={{ marginBottom: '34px' }}>
                    <div className='offer-3-select-time-container restaurant-opening-closing-hours'>
                      <div style={{ position: 'relative', marginRight: '15px' }}>
                        <img
                          src={timerIcon}
                          alt="icon"
                          style={{ position: 'absolute', left: '10px', top: '25%', pointerEvents: 'none', width: '20px' }}
                        />
                        <select
                          required
                          style={{
                            color: '#1D1E25',
                            fontWeight: '300',
                            paddingLeft: '35px',
                            WebkitAppearance: 'none',
                          }}
                          value={startTime}
                          onChange={handleStartTimeChange}
                        >
                          <option value="">{startTime ? startTime : 'Restoran Açılış*'}</option>
                          {hoursOptions.map((hour) => (
                            <option key={hour} value={hour}>{hour}</option>
                          ))}
                        </select>
                        {/* Sağdaki caret ikonu */}
                        <BsFillCaretDownFill style={{ width: '13px', position: 'absolute', right: '15px', top: '25%', zIndex: '999' }} />
                      </div>

                      <div style={{ position: 'relative', marginRight: '25px' }}>
                        <img
                          src={timerIcon}
                          alt="icon"
                          style={{ position: 'absolute', left: '10px', top: '25%', pointerEvents: 'none', width: '20px' }}
                        />
                        <select required style={{
                          color: '#1D1E25',
                          fontWeight: '300',
                          paddingLeft: '35px',
                          WebkitAppearance: 'none',
                        }} value={endTime} onChange={handleEndTimeChange}>
                          <option value="">{endTime ? endTime : 'Restoran Kapanış*  '}</option>
                          {hoursOptions.map((hour) => (
                            <option key={hour} value={hour}>{hour}</option>
                          ))}
                        </select>
                        <BsFillCaretDownFill style={{ width: '13px', position: 'absolute', right: '10px', top: '25%', zIndex: '999' }} className="dropdown-icon" />

                      </div>
                    </div>
                  </div>

                </div>
                <p style={{ marginTop: '-20px', fontSize: '12px', color: '#d23333' }}>
                  {companyInfoValidationResult?.workingHours?.length > 0 ? `${companyInfoValidationResult?.workingHours}` : ''}
                </p>
              </Form.Group>
              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='capacity' data-fluent-validation-group='create-company-request'></div>
                <div className="input-container">
                  <img className='icons' src={versionIcon} />
                  <Form.Control
                    type="number"
                    placeholder='İşletme Kapasitesi*'
                    name="capacity"
                    value={formData?.capacity != undefined ? formData?.capacity : ''}
                    onChange={handleChange}
                    required
                  />
                </div> */}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="İşletme Kapasitesi"
                    error={companyInfoValidationResult?.capacity?.length > 0}
                    helperText={companyInfoValidationResult?.capacity}
                    required
                    placeholder='İşletme Kapasitesi'
                    name="capacity"
                    variant="outlined"
                    fullWidth
                    type='tel'
                    style={{ marginBottom: '24px' }}
                    value={formData?.capacity != undefined ? formData?.capacity : ''}
                    onChange={handleChange}

                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={versionIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>
              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='webSiteLink' data-fluent-validation-group='create-company-request'></div>
                <div className="input-container website-link">
                  <p className='icons weblink' >Link: </p>
                  <Form.Control
                    type="text"
                    placeholder='Web Site Linki'
                    name="webSiteLink"
                    value={formData?.webSiteLink}
                    onChange={handleChange}
                  />
                </div> */}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    // label="Web Site Linki"
                    placeholder='Web Site Linki'
                    variant="outlined"
                    name="webSiteLink"
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value={formData?.webSiteLink}
                    onChange={handleChange}
                    type='text'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <p style={{ color: '#1D1E25', marginBottom: '0px' }}>Link:</p>
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>

              {formData?.logoUrl ? (
                <Form.Group controlId="inputArea">
                  <div className="invalid-feedback" data-fluent-validation-key='logoUrl' data-fluent-validation-group='create-company-request'></div>
                  <div style={{ justifyContent: 'unset', marginBottom: '0px' }} className='uploadImage' onClick={() => inputRef?.current?.click()}>
                    <div>
                      <img className='uploadImageIcon' style={{ width: '60px', height: '60px', padding: '0px' }} src={`${cdnBaseUrl}${formData?.logoUrl}`} />
                    </div>
                    <input
                      ref={inputRef}

                      style={{ display: 'none' }}
                      className='uploadImageInput'
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleLogoUrlChange}
                    />
                    <label style={{ color: '#89cda2', fontWeight: '700', fontSize: '16px' }}>İşletme Logosu Yüklendi ✅</label>
                  </div>
                </Form.Group>


              ) : (
                <Form.Group controlId="inputArea">
                  <div className="invalid-feedback" data-fluent-validation-key='logoUrl' data-fluent-validation-group='create-company-request'></div>
                  <div style={{ justifyContent: 'unset', marginBottom: '0px' }} className='uploadImage' onClick={() => inputRef?.current?.click()}>
                    <div>
                      <img className='uploadImageIcon' src={cameraActionIcon} />
                    </div>
                    <input
                      className='deneme'
                      ref={inputRef}

                      style={{ display: 'none' }}
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleLogoUrlChange}
                    />
                    <label>İşletme Logosu Yükleme</label>
                  </div>
                </Form.Group>


              )

              }

              <div className='infoPassword' style={{ marginTop: '8px', marginBottom: '24px' }}>
                <BsInfoCircle style={{ width: '14px', marginRight: '8px' }} />
                <p style={{ marginBottom: '0px' }}>Sadece 1 adet işletme logosu yükleyebilirsiniz.</p>
              </div>


              {formData?.photoUrl ? (

                <Form.Group controlId="inputArea">
                  <div className="invalid-feedback" data-fluent-validation-key='photoUrl' data-fluent-validation-group='create-company-request'></div>
                  <div className='uploadImage' style={{ justifyContent: 'unset', marginBottom: '0px' }} onClick={() => inputRef2?.current?.click()}>

                    <div>
                      <img className='uploadImageIcon' style={{ width: '60px', height: '60px', padding: '0px' }} src={`${cdnBaseUrl}${formData?.photoUrl}`} />
                    </div>
                    <input
                      ref={inputRef2}

                      style={{ display: 'none' }}
                      type="file"
                      className='uploadImageInput'
                      accept=".jpg,.jpeg,.png"
                      placeholder='İşletme Fotoğrafları Yükleme Alanı'
                      onChange={handleCompanyPhotos}
                    />
                    <label style={{ color: '#89cda2', fontWeight: '700', fontSize: '16px' }}>İşletme Galeri Fotoğrafı Yüklendi ✅ </label>
                  </div>
                </Form.Group>

              ) : (

                <Form.Group controlId="inputArea">
                  <div className="invalid-feedback" data-fluent-validation-key='photoUrl' data-fluent-validation-group='create-company-request'></div>
                  <div className='uploadImage' style={{ justifyContent: 'unset', marginBottom: '0px' }} onClick={() => inputRef2?.current?.click()}>
                    <div>
                      <img className='uploadImageIcon' src={cameraActionIcon} />
                    </div>
                    <input
                      ref={inputRef2}

                      style={{ display: 'none' }}
                      type="file"
                      className='uploadImageInput'
                      accept=".jpg,.jpeg,.png"
                      placeholder='İşletme Galeri Fotoğrafları Yükleme Alanı'
                      onChange={handleCompanyPhotos}
                    />
                    <label>İşletme Galeri Fotoğrafları Yükleme </label>
                  </div>
                </Form.Group>


              )

              }
              <div className='infoPassword' style={{ marginTop: '8px' }}>
                <BsInfoCircle style={{ width: '14px', marginRight: '8px' }} />
                <p style={{ marginBottom: '0px', }}>Sadece 1 adet işletme fotoğrafı yükleyebilirsiniz.</p>
              </div>

            </div>


            <div className='companyRegisterForm'>

              <h6 className="companyRegisterTitle">Firma Adres Bilgileri</h6>

              <Form.Group controlId="inputArea">
                {/* <div className="input-container ">
                  <img className='icons' src={TRIcon} />
                  <Form.Control
                    style={{ border: '1px solid #F15F50' }}
                    type="text"
                    placeholder='Türkiye'
                    name="ulke"
                    onChange={handleChange}
                    required
                    readOnly
                  />
                  <img className='icons' style={{ left: '120px' }} src={lockIcon} />
                </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name'
                    data-fluent-validation-group='create-company-request'
                    label="Ülke"
                    name="ulke"
                    required
                    select
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value="Türkiye"
                    onChange={handleChange}
                    SelectProps={{
                      IconComponent: () => <BsFillCaretDownFill style={{ width: '14px', marginRight: '15px' }} className="dropdown-icon" />
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={TRIcon} alt="TR Icon" />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF", borderRadius: '8px' }
                    }}
                  >
                    <MenuItem value="Türkiye">Türkiye</MenuItem>
                  </TextField>
                </Box>

              </Form.Group>

              <Form.Group controlId="inputArea">
                <div className="invalid-feedback" data-fluent-validation-key='city' data-fluent-validation-group='address'></div>
                <div className='select-container'>
                  <Form.Control
                    as='select'
                    placeholder='İSTANBUL'
                    name="city"
                    value={selectedCity?.name}
                    onChange={handleCityChange}
                    required
                  >
                    <option value="İSTANBUL" >İSTANBUL</option>
                    {cities.map((option, index) => (
                      <option key={index} value={cities[index].name}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                  <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />

                </div>
              </Form.Group>
              <Form.Group controlId="inputArea">
                <div className="invalid-feedback" data-fluent-validation-key='district' data-fluent-validation-group='address'></div>
                <div className='select-container'>
                  <Form.Control
                    as='select'
                    name="district"
                    value={districtId}
                    onChange={handleDistrictChange}
                    required
                  >
                    <option value="">İlçe*</option>
                    {districts.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                  <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />
                </div>
                <p style={{ fontSize: '12px', color: '#d23333' }}>
                  {addressInfoValidationResult?.district?.length > 0 ? `${addressInfoValidationResult?.district}` : ''}
                </p>
              </Form.Group>

              <Form.Group controlId="inputArea">
                <div className="invalid-feedback" data-fluent-validation-key='neighborhood' data-fluent-validation-group='address'></div>
                <div className='select-container'>
                  <Form.Control

                    as="select"
                    placeholder='Mahalle*'
                    name="neighborhood"
                    value={neighborhoodId}
                    onChange={handleNeighborhoodChange}
                    required
                  >
                    <option value="">Mahalle*</option>
                    {neighborhoods.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                  <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />
                </div>
                <p style={{ fontSize: '12px', color: '#d23333' }}>
                  {addressInfoValidationResult?.neighborhood?.length > 0 ? `${addressInfoValidationResult?.neighborhood}` : ''}
                </p>
              </Form.Group>


              <Form.Group controlId="inputArea">
                <div className="invalid-feedback" data-fluent-validation-key='street' data-fluent-validation-group='address'></div>
                <div className='select-container'>
                  <Form.Control

                    as="select"
                    placeholder='Cadde/Sokak*'
                    name="street"
                    value={streetName}
                    onChange={handleStreetChange}
                    required
                  >
                    <option value="">Cadde/Sokak*</option>
                    {streets.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                  <BsFillCaretDownFill style={{ width: '13px' }} className="dropdown-icon" />
                </div>

              </Form.Group>

              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='buildingNo' data-fluent-validation-group='address'></div>
                <Form.Control

                  type="text"
                  placeholder='Bina No*'
                  name="buildingNo"
                  value={formData?.address.buildingNo}
                  onChange={handleChange}
                /> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Bina No"
                    error={addressInfoValidationResult?.buildingNo?.length > 0}
                    helperText={addressInfoValidationResult?.buildingNo}
                    required
                    name="buildingNo"
                    placeholder='Bina No'
                    variant="outlined"
                    fullWidth
                    type='text'
                    value={formData?.address.buildingNo}
                    onChange={handleChange}
                  />

                </Box>
              </Form.Group>

              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='latitude' data-fluent-validation-group='location'></div>
                <Form.Control
                  type="text"
                  placeholder="Enlemi girin (Latitude)*"
                  name="latitude"
                  value={latitude}
                  onChange={handleLocationChange}
                /> */}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Enlemi girin (Latitude)"
                    required
                    placeholder="Enlemi girin (Latitude)"
                    name="latitude"
                    variant="outlined"
                    fullWidth
                    type='tel'
                    value={latitude}
                    onChange={handleLocationChange}
                  />

                </Box>
              </Form.Group>

              <Form.Group controlId="inputArea">

                {/* <Form.Group controlId="inputArea">
                  <div className="invalid-feedback" data-fluent-validation-key='longitude' data-fluent-validation-group='location'></div>
                  <Form.Control
                    type="text"
                    placeholder="Boylamı girin (Longitude)*"
                    name="longitude"
                    value={longitude}
                    onChange={handleLocationChange}
                    required
                  />
                </Form.Group> */}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='longitude' data-fluent-validation-group='create-company-request'
                    label="Boylamı girin (Longitude)"
                    required
                    placeholder="Boylamı girin (Longitude)"
                    name="longitude"
                    variant="outlined"
                    fullWidth
                    type='tel'
                    style={{ marginBottom: '24px' }}
                    value={longitude}
                    onChange={handleLocationChange}
                  />

                </Box>
              </Form.Group>

            </div>



            <div className='companyRegisterForm maps-location' style={{ marginTop: '108px', marginBottom: '40px' }}>
              <h6 className="companyRegisterTitle">Firma Harita Konumu </h6>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {latLng ? <GoogleMap lat={latLng.lat} lng={latLng.lng} /> : <GoogleMap lat={41.015137} lng={28.979530} />}
              </div>

            </div>


            <div className='companyRegisterForm'>

              <h6 className="companyRegisterTitle">Vergi Levhası Bilgileri</h6>

              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='tradeName' data-fluent-validation-group='tax-certificate'></div>
                  <div className="input-container">
                    <img className='icons' src={enterpriseIcon} />
                    <Form.Control
                      type="text"
                      placeholder={'Ticaret Ünvanı'}
                      name="tradeName"
                      value={formData?.taxCertificate?.tradeName}
                      onChange={handleChange}
                    />
                  </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Ticaret Ünvanı"
                    placeholder='Ticaret Ünvanı'
                    name="tradeName"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value={formData?.taxCertificate?.tradeName}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={enterpriseIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>


              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='taxOffice' data-fluent-validation-group='tax-certificate'></div>
                  <div className="input-container">
                    <img className='icons' src={enterpriseIcon} />
                    <Form.Control
                      type="text"
                      placeholder={'Vergi Dairesi'}
                      name="taxOffice"
                      value={formData?.taxCertificate?.taxOffice}
                      onChange={handleChange}
                    />
                  </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Vergi Dairesi"
                    placeholder='Vergi Dairesi'
                    name="taxOffice"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value={formData?.taxCertificate?.taxOffice}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={enterpriseIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>

              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='taxNo' data-fluent-validation-group='tax-certificate'></div>
                    <div className="input-container">
                      <img className='icons' src={enterpriseIcon} />
                      <Form.Control
                        type="text"
                        placeholder={'Vergi Kimlik No'}
                        name="taxNo"
                        value={formData?.taxCertificate?.taxNo !== undefined ? formData?.taxCertificate?.taxNo : ''}
                        onChange={handleChange}
                      />
                    </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Vergi Kimlik No"
                    placeholder='Vergi Kimlik No'
                    variant="outlined"
                    name="taxNo"
                    type='tel'
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value={formData?.taxCertificate?.taxNo !== undefined ? formData?.taxCertificate?.taxNo : ''}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={enterpriseIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>

              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='taxPayerName' data-fluent-validation-group='tax-certificate'></div>
                  <div className="input-container">
                    <img className='icons' src={enterpriseIcon} />
                    <Form.Control
                      type="text"
                      placeholder={'Mükellefin Adı Soyadı'}
                      name="taxPayerName"
                      value={formData?.taxCertificate?.taxPayerName !== undefined ? formData?.taxCertificate?.taxPayerName : ''}
                      onChange={handleChange}
                    />
                  </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Mükellefin Adı Soyadı"
                    placeholder='Mükellefin Adı Soyadı'
                    variant="outlined"
                    name="taxPayerName"
                    fullWidth
                    style={{ marginBottom: '24px' }}
                    value={formData?.taxCertificate?.taxPayerName !== undefined ? formData?.taxCertificate?.taxPayerName : ''}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={enterpriseIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>

              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='identificationNumber' data-fluent-validation-group='tax-certificate'></div>
                <div className="input-container">
                  <img className='icons' src={enterpriseIcon} />
                  <Form.Control
                    type="text"
                    placeholder={'TC Kimlik No'}
                    name="identificationNumber"
                    value={formData?.taxCertificate?.identificationNumber !== undefined ? formData?.taxCertificate?.identificationNumber : ''}
                    onChange={handleChange}
                  />
                </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="TC Kimlik No"
                    placeholder='TC Kimlik No'
                    variant="outlined"
                    name="identificationNumber"
                    fullWidth
                    type='tel'
                    style={{ marginBottom: '24px' }}
                    value={formData?.taxCertificate?.identificationNumber !== undefined ? formData?.taxCertificate?.identificationNumber : ''}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={enterpriseIcon} />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>
            </div>


            <div className='companyRegisterForm'>


              <h6 className="companyRegisterTitle">Firma Şifre Belirleme</h6>
              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='email' data-fluent-validation-group='create-company-request'></div>
                <div className="input-container">
                  <img className='icons' src={emailIcon} />
                  <Form.Control
                    type="text"
                    placeholder='E-Mail Adresi*'
                    name="email"
                    value={formData?.email}
                    onChange={handleChange}
                    required
                  />
                </div> */}


                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="E-Mail Adresi"
                    required
                    placeholder='E-Mail Adresi'
                    name="email"
                    variant="outlined"
                    fullWidth
                    type='text'
                    value={formData?.email}
                    style={{ marginBottom: '24px' }}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={emailIcon} />
                        </InputAdornment>
                      )
                    }}
                    error={companyInfoValidationResult?.email?.length > 0}
                    helperText={companyInfoValidationResult?.email}
                  />

                </Box>


              </Form.Group>


              <Form.Group controlId="inputArea">
                {/* <div className="invalid-feedback" data-fluent-validation-key='password' data-fluent-validation-group='create-company-request'></div>
                <div style={{ display: 'flex' }} className="input-container">
                  <img className='icons' src={keyIcon} />
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder='Şifre*'
                    name="password"
                    value={formData?.password}
                    onChange={handlePasswordChange}
                    required
                  />

                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn eyeButton"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                </div> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Şifre"
                    required
                    placeholder='Şifre'
                    name="password"
                    variant="outlined"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={formData?.password}
                    onChange={handlePasswordChange}
                    error={companyInfoValidationResult?.password?.length > 0}
                    helperText={companyInfoValidationResult?.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={keyIcon} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn eyeButton"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </button>
                          </div>
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
              </Form.Group>
              <div className='infoPassword'>
                <BsInfoCircle style={{ width: '30px' }} />
                <p style={{ marginBottom: '0px' }}>Şifreniz en az 6 karakter uzunluğunda olmalıdır.</p>
              </div>
              <Form.Group controlId="inputArea">
                {/* <div style={{ display: 'flex' }} className="input-container">
                  <img className='icons' src={keyIcon} />
                  <Form.Control

                    type={showPassword ? "text" : "password"}
                    placeholder='Şifre Tekrarı'
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handlePasswordConfirmChange}
                  />

                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn eyeButton"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>

                </div> */}

                <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    data-fluent-validation-key='name' data-fluent-validation-group='create-company-request'
                    label="Şifre Tekrarı"
                    required
                    type={showPassword ? "text" : "password"}
                    placeholder='Şifre Tekrarı'
                    name="confirmPassword"
                    value={confirmPassword}
                    variant="outlined"
                    fullWidth
                    onChange={handlePasswordConfirmChange}
                    error={companyInfoValidationResult?.password?.length > 0}
                    helperText={companyInfoValidationResult?.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={keyIcon} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn eyeButton"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </button>
                          </div>
                        </InputAdornment>
                      ),
                      style: { backgroundColor: "#FFFF" }
                    }}
                  />

                </Box>
                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

              </Form.Group>
              {/* 
              <h6 style={{ marginTop: '80px' }} className='companyInfoTitle'>İşletme Sözleşmesi</h6>
              <Form.Group controlId="inputArea">
                <div className="input-container">
                  <img className='icons' src={pdfIcon} />
                  <Form.Control
                    style={{ border: '1px solid #F15F50' }}
                    type="text"
                    name='contractUrl1'
                    placeholder={formData?.contractUrl1 ? formData?.contractUrl1 : 'Sözleşme 1'}
                    value={formData?.contractUrl1}
                    readOnly
                    required
                  />

                </div>
              </Form.Group>
              <Form.Group controlId="inputArea">
                <div className="input-container">
                  <img className='icons' src={pdfIcon} />
                  <Form.Control
                    style={{ border: '1px solid #F15F50' }}
                    type="text"
                    name='contractUrl2'
                    placeholder={formData?.contractUrl2 ? formData?.contractUrl2 : 'Sözleşme 2'}
                    value={formData?.contractUrl2}
                    readOnly
                    required
                  />
                </div>
              </Form.Group>


              <h6 className="companyInfoTitle">İşletme Sözleşmesi İmzalı, Taranmış</h6>
              <div className='uploadPDF' onClick={() => inputRef3?.current?.click()}>
                <Form.Group controlId="inputArea">
                  <div className='uploadPDFIcon-img-container'>
                    <img className='uploadPDFIcon' src={uploadIcon} />
                    <input
                      ref={inputRef3}
                      style={{ display: 'none' }}
                      type="file"
                      className='uploadPDF'
                      accept=".pdf"
                      onChange={(e) => handleContractUpload(e, 'contractUrl1')}
                    />
                  </div>

                </Form.Group>
                {
                  contractUrl1 ? (
                    <div>
                      <label style={{ color: '#89cda2', fontWeight: '700', fontSize: '16px' }}>Sözleşmenizin imzalı ve taranmış / fotoğraf hali bu alana yüklendi.</label>
                    </div>
                  ) : (
                    <div>
                      <label>Sözleşmenizin imzalı ve taranmış / fotoğraf halini bu alana yüklemelisiniz.</label>
                    </div>
                  )
                }

              </div> */}
              {/* <div className='uploadPDF' onClick={() => inputRef4?.current?.click()}>
                <Form.Group controlId="inputArea">
                  <div>
                    <img className='uploadPDFIcon' src={uploadIcon} />
                  </div>
                  <input
                    ref={inputRef4}
                    style={{ display: 'none' }}
                    type="file"
                    className='uploadPDFInput'
                    accept=".pdf"
                    onChange={(e) => handleContractUpload(e, 'contractUrl2')}
                  />
                </Form.Group>
                {
                  contractUrl2 ? (
                    <div>
                      <label style={{ color: '#89cda2', fontWeight: '700', fontSize: '16px' }}>Sözleşmenizin imzalı ve taranmış / fotoğraf hali bu alana yüklendi.</label>
                    </div>
                  ) : (
                    <div>
                      <label>Sözleşmenizin imzalı ve taranmış / fotoğraf hali bu alana yüklendi.</label>
                    </div>
                  )
                }
              </div> */}
              {/* {formData.contractUrl1 && (
                <div className="mb-3">
                  <a href={formData.contractUrl1} target="_blank" rel="noopener noreferrer">Contract 1</a>
                </div>
              )}
              {formData.contractUrl2 && (
                <div className="mb-3">
                  <a href={formData.contractUrl2} target="_blank" rel="noopener noreferrer">Contract 2</a>
                </div>
              )}  */}
            </div>

            <div style={{ marginBottom: '72px' }} className='companyRegisterForm'>

              <h6 className="companyRegisterTitle">Firma Sözleşme Onayı</h6>
              <Box className='contract-flash-offer' sx={{ padding: '16px' }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={2} sm={1}>
                    <OrangeCheckbox className='checkbox' onChange={handleCheckboxChange} checked={checkboxChecked} />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography variant="body1">
                      Şirket yetkilisi olarak, şirket adına paylaştığım tüm bilgilerin tam, doğru ve güncel olduğunu kabul, beyan ve taahhüt ederim.
                      Şirket yetkilisi olarak,
                      <ButtonBase onClick={handleAgreementOpen} sx={{ color: '#F15F50', textDecoration: 'underline', padding: 0 }}>
                        İş Birliği Sözleşmesi
                      </ButtonBase>’ni okuduğumu ve elektronik ortamda onayladığımı kabul, beyan ve taahhüt ederim.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Modal show={openAgreementUrl} onHide={handleAgreementClose} size='lg'>
                <AgreementScreen
                  onClose={handleAgreementClose}
                  agreementText={agreementText}
                />

              </Modal>
              {showWarning && <p style={{ color: 'red' }}>Lütfen kullanım koşullarını kabul edin.</p>}
            </div>
            <Button disabled={!isValidPassword} className='signUpButton' onClick={handleSubmit} type="button" >

              <img src={arrow} /> Üyeliği Tamamla
            </Button>

          </Form>

          <div className='links-container' style={{ marginTop: '0px', justifyContent: 'flex-end' }}>
            <div style={{ marginRight: '0px' }} className='Links'>
              <a target='_blank' href={documentAboutUsUrl} className='link'>Hakkımızda</a>
              <a target='_blank' href={documentTermsAndConditionsUrl} className='link'>Şartlar ve Koşullar</a>
              <a target='_blank' href={documentPrivacyUrl} className='link'>Gizlilik Politikası</a>

            </div>
          </div>
        </Col>
      </Row >

    </Container >

  );
};

export default RegisterScreen;