import React, { useEffect, useState } from 'react'
import '../styles/cominSoonStyles.css'
import { FaCalendar, FaHeartbeat, FaTimes } from 'react-icons/fa'
import { AiOutlineCalendar } from 'react-icons/ai'
import { Button, Form } from 'react-bootstrap'
import Modal from 'react-modal'
import { Alert, Checkbox, Grid, TextField, colors } from '@mui/material'
import { CreateCampaignRequest } from '@/model/request/CreateCampaignRequest'

import { CampaignDTO } from '@/model/dto/CampaignDTO'
import { MdStop } from 'react-icons/md'
import StopCampaignSessionPopup from '../popup/StopCampaignSessionPopup'
import baseUrl, { cdnBaseUrl } from '../../api/baseUrl'
import FlashOfferDetailsPopup from '../popup/FlashOfferDetailsPopup'
import moment from 'moment'
import StopFlashDealPopup from '../popup/StopFlashDealPopup'
import { toast } from 'react-toastify';
import { Repository } from '../../data/repository/repository'

import { ImageDTO } from '../../model/dto/ImageDTO'
import { QRCodeDTO } from '../../model/dto/QRCodeDTO'
import {TimeHelper} from "../../helpers/TimeHelper";

type FormData = {
  [key: string]: { [key: string]: string }
} & {
  offer1: { field1: string; field2: string; imgUrl: string };
  offer2: { field1: string; field2: string; imgUrl: string };
  offer3: { field1: string; field2: string; imgUrl: string };
};

export default function FlashOfferScreen() {


  const bg = require('../images/Sol_color.png')
  const leftImg = require('../images/flash-offer.png')
  const leftSecondImg = require('../images/icons/Sol_color-2.png')
  const arrow = require('../images/icons/Text--new-line.png')
  const upload = require('../images/icons/Cloud--upload.png')
  const heartbeatIcon = require('../images/icons/Activity.png')
  const [openFlashOffer, setOpenFlashOffer] = useState<boolean>(false)
  const [selectedOffer, setSelectedOffer] = useState<string>('offer1');
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [showStopSessionModal, setShowStopSessionModal] = useState<boolean>(false);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [consumedQRCount, setConsumedQRCount] = useState<[QRCodeDTO] | null>();
  const [activeFlashDealInfo, setActiveFlashDealInfo] = useState<CampaignDTO | null>()
  const [uploadImageResult, setUploadImageResult] = useState<ImageDTO | null>()
  const [activeDeal, setActiveDeal] = useState<boolean>(false)
  const [formData, setFormData] = useState<FormData>({
    offer1: { field1: '', field2: '', imgUrl: '' },
    offer2: { field1: '', field2: '', imgUrl: '' },
    offer3: { field1: '', field2: '', imgUrl: '' }
  });



  const [selectedUsers, setSelectedUsers] = useState<string | number | null>(null);
  const userOptions = [20, 30, 40, 50, 75, 100, '100+'];

  const handleUserSelection = (users: string | number) => {
    setSelectedUsers(users);
  };


  const handleCloseStopSessionModal = () => {
    setShowStopSessionModal(false);
  };
  const handleStopSessionClick = () => {
    setShowStopSessionModal(true);
    console.log('clicked');

  };



  const handleDeleteImg = () => {
    setUploadImageResult(null)
  }


  const isFormValid = () => {
    const data = formData[selectedOffer];
    return Object.values(data).every(value => value.trim() !== '');
  };


  const formatTime = (time: string) => {
    const [hour, minute] = time.split(':');

    const date = new Date();
    date.setHours(Number(hour));
    date.setMinutes(Number(minute));
    date.setSeconds(0);
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var year = date.getFullYear();
    var hours = date.getHours().toString().padStart(2, '0');
    var minutes = date.getMinutes().toString().padStart(2, '0');
    var seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const now = new Date();
  const currentTimeString = `Şimdi`;

  const tempHoursOptions = [currentTimeString, ...Array.from({ length: 25 }, (_, i) => i)
    .filter(hour => hour > now.getHours())
    .map(hour => `${hour}:00`)];

  const startHoursOptions = tempHoursOptions.filter((item, index) => tempHoursOptions.indexOf(item) === index);
  const endHoursOptions = startHoursOptions.filter((item, index) => index !== 0);

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

    var startTimeFormatted = formatTime(event.target.value);

    if (event.target.value === 'Şimdi') {
      var dtNow = new Date();
      var strNow = `${dtNow.getHours()}:${dtNow.getMinutes().toString().padStart(2, '0')}`;

      startTimeFormatted = formatTime(strNow);
    }

    setStartTime(startTimeFormatted);
  };
  const handleEndTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const endTimeFormatted = formatTime(event.target.value);
    setEndTime(endTimeFormatted);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [selectedOffer]: {
        ...prevState[selectedOffer],
        [name]: value
      }
    }));
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(e.target.checked);
  };


  const openNewFlashOffer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpenFlashOffer(true)
  }
  const handleLogoUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      try {
        const uploadResult = await Repository.uploadFile(file) ;
        setUploadImageResult(uploadResult)
        setFormData(prevFormData => ({
          ...prevFormData,
          [selectedOffer]: {
            ...prevFormData[selectedOffer],
            imgUrl: uploadResult?.url ?? ''
          }
        } ));
      } catch (error) {
        console.error('Resim yükleme hatası:', error);
      }
    }
  };

  const handleClick = () => {
    const inputElement = document.getElementById('hiddenInput') as HTMLInputElement | null;
    if (inputElement) {
      inputElement.click();
    }
  }

  console.log(startTime);
  console.log(endTime);


  const handleShowDetailModal = () => {
    setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
  };


  const handleSubmit = async () => {
    if (!checkboxChecked) {
      setShowWarning(true);
      return;
    }
    setShowWarning(false);

    var formatString = "DD/MM/YYYY HH:mm:ss";

    var dtStart = moment(startTime, formatString).toDate();
    var dtEnd = moment(endTime, formatString).toDate();

    if (dtStart.getTime() === dtEnd.getTime()) {
      toast.error('Başlangıç tarihi ve bitiş tarihi aynı olamaz')
      return;
    }
    else if (dtStart.getTime() > dtEnd.getTime()) {
      toast.error('Başlangıç tarihi bitiş tarihinden büyük olamaz')
      return;
    }

    const flashOfferData: CreateCampaignRequest = {
      promotionType: 1,
      flashDealType: selectedOffer === 'offer1' ? 0 : selectedOffer === 'offer2' ? 1 : 2,
      startDate: selectedOffer === 'offer3' ? startTime : TimeHelper.now().format('YYYY-MM-DDTHH:mm:ss'),
      finishDate: selectedOffer === 'offer3' ? endTime : undefined,
      maxUsageCount: selectedOffer === 'offer2' ? Number(selectedUsers) : undefined,
      companyId: Repository.getMyCompany()?.id ?? '',
      agreementUrl: '',
      title: formData[selectedOffer].field1,
      content: formData[selectedOffer].field2,
      imageUrl: formData[selectedOffer].imgUrl

    }
    try {
      const response = await Repository.createFlashDeal(flashOfferData);
      if (response) {
        console.log('Kampanya başarıyla oluşturuldu:', response);
        fetchCampaignsData()

        setOpenFlashOffer(false)
        setActiveDeal(true)

      } else {
        console.log('Kampanya oluşturulamadı.');

      }
    } catch (error) {
      console.error('Kampanya oluşturma hatası:', error);
    }

  };

  const fetchCampaignsData = async () => {
    try {
      const campaigns = await Repository.fetchMyCampaigns();
      const now = new Date();

      if (Array.isArray(campaigns)) {
        const activeCampaigns = campaigns.filter((campaign: any) => {
          const startDate = moment(campaign.startDate, "DD/MM/YYYY HH:mm:ss").toDate();
          const finishDate = moment(campaign.finishDate, "DD/MM/YYYY HH:mm:ss").toDate();

          return finishDate > new Date() && (campaign.isActive || startDate > new Date());
        }
        );

        const promotionTypeOneCampaigns = activeCampaigns.filter((campaign: any) => campaign.promotionType === 1);
        if (promotionTypeOneCampaigns.length > 0) {
          setActiveFlashDealInfo(promotionTypeOneCampaigns[0])
          setActiveDeal(true)

        } else {
          console.log('aktif flash deal bulunmuyor');
          setActiveDeal(false)
        }


      } else {
        console.error('Beklenmeyen veri formatı:', campaigns);
      }
    } catch (error) {
      console.error('Kampanyalar alınırken hata oluştu:', error);
    }
  }





  const fetchQrCodes = async () => {
    if (activeFlashDealInfo) {
      const getQRCodes = await Repository.getQrCodeByCampaignId(activeFlashDealInfo!.id, Repository.getMyCompany()?.id ?? '', '1')
      setConsumedQRCount(getQRCodes)

    }
  }
  useEffect(() => {
    fetchCampaignsData()
  }, [])

  useEffect(() => {
    fetchQrCodes()
  }, [activeFlashDealInfo])



  return (
    <div className='container-fluid'>
      <div className='row'>

        {
          openFlashOffer ? (
            <div className='col-sm-2 backgroundDiv'>
              <img style={{ height: '100%' }} src={leftSecondImg} />
            </div>
          ) : (
            <div className='col-sm-2 backgroundDiv'>
              <img style={{ height: '100%' }} src={bg} />

            </div>
          )

        }


        <div className='col-sm-10 flash-offer-content-container'>
          {openFlashOffer ? (
            // flash offer form alani 
            <div className='flash-offer-form'>
              <Form onSubmit={handleSubmit}>
                <h4 style={{ marginTop: '20px', textAlign: 'left', fontWeight: '100', fontSize: '32px' }}>Yeni Flash Teklif Başlat ⚡️</h4>
                <hr style={{ width: '95%' }} />
                <p className='flash-offer-form-paragraph'>Aşağıdaki adımları takip ederek hemen başlayabilirsiniz:</p>
                <div className='flash-offer-select-button-container'>
                  <button className={`flash-offer-select-button ${selectedOffer === 'offer1' ? 'selected' : ''}`} type="button" onClick={() => setSelectedOffer('offer1')}>Sınırsız Bir Süre</button>
                  <button className={`flash-offer-select-button ${selectedOffer === 'offer2' ? 'selected' : ''}`} type="button" onClick={() => setSelectedOffer('offer2')}>Kullanım Sayısına Bağlı</button>
                  <button className={`flash-offer-select-button ${selectedOffer === 'offer3' ? 'selected' : ''}`} type="button" onClick={() => setSelectedOffer('offer3')}>Bir Zaman Aralığına Bağlı</button>


                </div>
                {selectedOffer === 'offer1' && (
                  <div>
                    <p style={{ color: 'rgb(205, 54, 54)' }} className='flash-offer-offer-1-paragraph'>Sınırsız bir süre seçtiğinizde, kampanya başlangıç günü boyunca açık kalacaktır. Örnek olarak 13:00’de başlatılmış bir Flash Deal gün sonu olan 23:59’da otomatik kapanacakdır. Veya istediğiniz zaman kendiniz Flash Deal’a son verebilirsiniz.</p>
                    <input
                      className='offer-1-title-input'
                      type="text"
                      name="field1"
                      value={formData.offer1.field1}
                      onChange={handleChange}
                      placeholder="Başlık"
                    />
                    <input
                      className='offer-1-content-input'
                      type="text"
                      name="field2"
                      value={formData.offer1.field2}
                      onChange={handleChange}
                      placeholder="Açıklama"
                    />
                    {uploadImageResult ? (
                      <div className='upload-image-flash-offer'>
                        <div style={{ display: 'block' }}>
                          <label style={{ fontSize: '16px', textAlign: 'left', marginLeft: '-110px' }}>Kampanya Gōrseli</label><br />
                          <button className='delete-flash-offer-img' onClick={handleDeleteImg}>Sil</button>
                        </div>

                        <Form.Group controlId="inputArea">
                          <input

                            id="hiddenInput"
                            className='flash-offer-upload-img-input'
                            style={{ marginTop: '-13px', display: 'none' }}
                            type="file"
                            accept="image/*"
                            onChange={handleLogoUrlChange}


                          />
                        </Form.Group>
                        <div>
                          <img className='uploadImageIcon' style={{ width: '280px', height: '180px', padding: '0px', marginRight: '20px', }} src={`${cdnBaseUrl}${uploadImageResult.url}`} />

                        </div>
                      </div>

                    ) : (
                      <div style={{ justifyContent: 'flex-start' }} className='upload-image-flash-offer'>
                        <div>
                          <img className='flash-offer-upload-image-icon' src={upload}
                            onClick={handleClick}
                          />

                        </div>
                        <Form.Group controlId="inputArea">
                          <input

                            id="hiddenInput"
                            className='flash-offer-upload-img-input'
                            style={{ marginTop: '-13px', marginLeft: '-88px', display: 'none' }}
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleLogoUrlChange}


                          />
                        </Form.Group>
                        <label>Kampanya için görsel yükleyin.</label>

                      </div>
                    )

                    }

                    <div className='contract'>
                      <Checkbox onChange={handleCheckboxChange} checked={checkboxChecked} />
                      <p>Devam ederek, <a href={`${baseUrl}media/documents/a38ebe46-cfcd-42b8-8665-d181f275147c.pdf `} target='_blank'>Kullanım Koşullarını</a>, <a href={`${baseUrl}media/documents/2010848f-f535-447f-a15d-3716f4216fb0.pdf`} target='_blank'>Kullanıcı Sözleşmelerini</a> ve <a href={`${baseUrl}media/documents/2010848f-f535-447f-a15d-3716f4216fb0.pdf`} target='_blank'>Gizlilik Politikasını</a> kabul etmiş olursunuz.</p>
                    </div>
                    {showWarning && <p style={{ color: 'red' }}>Lütfen kullanım koşullarını kabul edin.</p>}

                  </div>

                )}

                {selectedOffer === 'offer2' && (
                  <div>
                    <div className='offer-2-buttons-container'>
                      <p>Kaç kullanıcı bu tekliften yararlanabilir?</p>
                      <div>
                        {userOptions.map((option) => (
                          <button type='button' className={`flash-offer-offer-2-buttons ${selectedUsers === option ? 'active' : ''}`}
                            key={option}
                            onClick={() => handleUserSelection(option)}
                            style={{

                            }}
                          >
                            {selectedUsers === option && <span role="img" aria-label="icon" style={{ marginRight: '5px' }}>🧒🏻</span>}
                            {option}
                          </button>
                        ))}
                      </div>
                      {/* {selectedUsers && <p>Seçilen kullanıcı sayısı: {selectedUsers}</p>} */}
                    </div>

                    <input
                      className='offer-1-title-input'
                      type="text"
                      name="field1"
                      value={formData.offer2.field1}
                      onChange={handleChange}
                      placeholder="Başlık"
                    />
                    <input
                      className='offer-1-content-input'
                      type="text"
                      name="field2"
                      value={formData.offer2.field2}
                      onChange={handleChange}
                      placeholder="Açıklama"
                    />

                    {
                      uploadImageResult ? (
                        <div className='upload-image-flash-offer'>
                          <div style={{ display: 'block' }}>
                            <label style={{ fontSize: '16px', textAlign: 'left', marginLeft: '-110px' }}>Kampanya Gōrseli</label><br />
                            <button className='delete-flash-offer-img' onClick={handleDeleteImg}>Sil</button>
                          </div>
                          <Form.Group controlId="inputArea">
                            <input

                              id="hiddenInput"
                              className='flash-offer-upload-img-input'
                              style={{ marginTop: '-13px', marginLeft: '-88px', display: 'none' }}
                              type="file"
                              accept="image/*"
                              onChange={handleLogoUrlChange}


                            />
                          </Form.Group>
                          <div>
                            <img className='uploadImageIcon' style={{ width: '280px', height: '180px', padding: '0px', marginRight: '20px', }} src={`${cdnBaseUrl}${uploadImageResult.url}`} />

                          </div>
                        </div>
                      ) : (
                        <div style={{ justifyContent: 'flex-start' }} className='upload-image-flash-offer'>
                          <div>
                            <img className='flash-offer-upload-image-icon' src={upload}
                              onClick={handleClick}
                            />

                          </div>
                          <Form.Group controlId="inputArea">
                            <input

                              id="hiddenInput"
                              className='flash-offer-upload-img-input'
                              style={{ marginTop: '-13px', marginLeft: '-88px', display: 'none' }}
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              onChange={handleLogoUrlChange}


                            />
                          </Form.Group>
                          <label>Kampanya için görsel yükleyin.</label>
                        </div>
                      )
                    }

                    <div className='contract'>
                      <Checkbox onChange={handleCheckboxChange} checked={checkboxChecked} />
                      <p>Devam ederek, <a href={`${baseUrl}media/documents/a38ebe46-cfcd-42b8-8665-d181f275147c.pdf `} target='_blank'>Kullanım Koşullarını</a>, <a href={`${baseUrl}media/documents/2010848f-f535-447f-a15d-3716f4216fb0.pdf`} target='_blank'>Kullanıcı Sözleşmelerini</a> ve <a href={`${baseUrl}media/documents/2010848f-f535-447f-a15d-3716f4216fb0.pdf`} target='_blank'>Gizlilik Politikasını</a> kabul etmiş olursunuz.</p>
                    </div>
                    {showWarning && <p style={{ color: 'red' }}>Lütfen kullanım koşullarını kabul edin.</p>}
                  </div>
                )}

                {selectedOffer === 'offer3' && (
                  <div>
                    <div className='offer-3-buttons-container'>
                      <p>Kampanya Başlangıç ve Bitiş Zamanı Seçin</p>
                      <div className='offer-3-select-time-container'>
                        <div style={{ marginRight: '40px' }}>
                          <select style={{ width: '380px' }} value={startTime} onChange={handleStartTimeChange}>
                            <option value="">{startTime ? startTime : 'Flash Teklif Başlangıç Zamanı'}</option>
                            {startHoursOptions.map((hour) => (
                              <option key={hour} value={hour}>{hour}</option>
                            ))}

                          </select>
                        </div>
                        <div>
                          <select style={{ width: '380px' }} value={endTime} onChange={handleEndTimeChange}>
                            <option value="">{endTime ? endTime : 'Flash Teklif Bitiş Zamanı'}</option>
                            {endHoursOptions.map((hour) => (
                              <option key={hour} value={hour}>{hour}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <input
                      className='offer-1-title-input'
                      type="text"
                      name="field1"
                      value={formData.offer3.field1}
                      onChange={handleChange}
                      placeholder="Başlık"
                    />
                    <input
                      className='offer-1-content-input'
                      type="text"
                      name="field2"
                      value={formData.offer3.field2}
                      onChange={handleChange}
                      placeholder="Açıklama"
                    />
                    {
                      uploadImageResult ? (
                        <div className='upload-image-flash-offer'>
                          <div style={{ display: 'block' }}>
                            <label style={{ fontSize: '16px', textAlign: 'left', marginLeft: '-110px' }}>Kampanya Gōrseli</label><br />
                            <button className='delete-flash-offer-img' onClick={handleDeleteImg}>Sil</button>
                          </div>

                          <Form.Group controlId="inputArea">
                            <input

                              id="hiddenInput"
                              className='flash-offer-upload-img-input'
                              style={{ marginTop: '-13px', marginLeft: '-88px', display: 'none' }}
                              type="file"
                              accept="image/*"
                              onChange={handleLogoUrlChange}


                            />
                          </Form.Group>
                          <div>
                            <img className='uploadImageIcon' style={{ width: '280px', height: '180px', padding: '0px', marginRight: '20px', }} src={`${cdnBaseUrl}${uploadImageResult.url}`} />

                          </div>
                        </div>
                      ) : (
                        <div style={{ justifyContent: 'flex-start' }} className='upload-image-flash-offer'>
                          <div>
                            <img className='flash-offer-upload-image-icon' src={upload}
                              onClick={handleClick}
                            />

                          </div>
                          <Form.Group controlId="inputArea">
                            <input

                              id="hiddenInput"
                              className='flash-offer-upload-img-input'
                              style={{ marginTop: '-13px', marginLeft: '-88px', display: 'none' }}
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              onChange={handleLogoUrlChange}


                            />
                          </Form.Group>
                          <label>Kampanya için görsel yükleyin.</label>
                        </div>
                      )
                    }

                    <div className='contract'>
                      <Checkbox onChange={handleCheckboxChange} checked={checkboxChecked} />
                      <p>Devam ederek, <a href={`${baseUrl}media/documents/a38ebe46-cfcd-42b8-8665-d181f275147c.pdf `} target='_blank'>Kullanım Koşullarını</a>, <a href={`${baseUrl}media/documents/2010848f-f535-447f-a15d-3716f4216fb0.pdf`} target='_blank'>Kullanıcı Sözleşmelerini</a> ve <a href={`${baseUrl}media/documents/2010848f-f535-447f-a15d-3716f4216fb0.pdf`} target='_blank'>Gizlilik Politikasını</a> kabul etmiş olursunuz.</p>
                    </div>
                    {showWarning && <p style={{ color: 'red' }}>Lütfen kullanım koşullarını kabul edin.</p>}
                  </div>
                )}
                <div>
                  {/* <button className='preview'><AiOutlineCalendar style={{ marginRight: '5px', color: 'red' }} />Ön İzleme
                  </button> */}
                  <Button
                    disabled={!isFormValid()}
                    className='flash-offer-button'
                    onClick={handleSubmit}
                  >
                    <img src={arrow} /> Yeni Flash Teklif Başlat
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            <div>
              <div className='flash-offer-title'>
                <h4 style={{ marginTop: '20px', textAlign: 'left', fontWeight: '100', fontSize: '30px' }}>Flash Teklif Alanına Hoş Geldin</h4>
                {/* <button className='preview'><AiOutlineCalendar style={{ marginRight: '5px', color: 'red' }} />Geçişi Görüntüle</button> */}
              </div>
              <hr style={{ width: '95%' }} />
              {activeDeal && activeFlashDealInfo ? (
                <div className='active-flash-deal-container'>
                  <h6><img style={{ color: '#F15F50', width: '25px' }} src={heartbeatIcon} /> Şu anda aktif bir flash teklifin var.</h6>
                  <div className='active-flash-deal-info'>
                    <label className="flash-deal-type-title">
                      {(() => {
                        switch (activeFlashDealInfo.flashDealType) {
                          case 0:
                            return '#Sınırsız Bir Süre';
                          case 1:
                            return '#Kullanım Sayısına Bağlı';
                          case 2:
                            return '#Bir Zaman Aralığına Bağlı';
                          default:
                            return '';
                        }
                      })()}
                    </label>
                    <div>
                      <label>Başlık</label>
                      <h5>{activeFlashDealInfo?.title}</h5>
                    </div>
                    <div>
                      <img style={{ width: '250px', borderRadius: '12px', height: '170px', marginBottom: '25px' }} src={`${cdnBaseUrl}${activeFlashDealInfo.imageUrl}`} />
                    </div>

                    <div>
                      <div>
                        <label>Flas Teklifin Bitiş Tarihi</label>
                        <label>Kaç Kullanıcı Yararlandı</label>
                      </div>
                      <div className='active-flash-deal-info-details'>
                        <p>{activeFlashDealInfo?.finishDate}</p>

                        {activeFlashDealInfo?.flashDealType === 1 ? (
                          <p style={{ marginRight: '200px' }}>
                            {activeFlashDealInfo?.usageCount === undefined ? `${activeFlashDealInfo.maxUsageCount}/0` : `${activeFlashDealInfo.maxUsageCount}/${activeFlashDealInfo.usageCount}`}
                          </p>
                        ) : (
                          <p style={{ marginRight: '200px' }}>
                            {activeFlashDealInfo?.usageCount === undefined ? '0' : activeFlashDealInfo.usageCount}
                          </p>
                        )}


                        <button style={{ width: '135px' }} className='flash-detail-popup' onClick={handleShowDetailModal}>Detayları Gör</button>
                        <div className='flash-details'>
                          <Modal
                            isOpen={showDetailModal}
                            onRequestClose={handleCloseDetailModal}
                            overlayClassName="custom-overlay"
                          >
                            <FlashOfferDetailsPopup onClose={handleCloseDetailModal} activeFlashDealInfo={activeFlashDealInfo} />
                          </Modal>
                        </div>
                        <button style={{ width: '200px', fontSize: '13px', height: '20px', alignItems: 'center', marginLeft: '15px', display: 'flex', alignContent: 'center' }} className='stop-session-button' onClick={handleStopSessionClick}><MdStop style={{ color: '#F15F50' }} />Flash Teklifi Durdur</button>
                      </div>


                      <div className='stop-session-container'>
                        <Modal
                          isOpen={showStopSessionModal}
                          onRequestClose={handleCloseStopSessionModal}
                          contentLabel="Stop Session Modal"
                          overlayClassName="custom-overlay stop-session-pop-up"
                        >
                          <StopFlashDealPopup onClose={handleCloseStopSessionModal} activeFlashDealInfo={activeFlashDealInfo} />
                        </Modal>
                      </div>
                    </div>

                  </div>
                </div>
              ) : (
                <div className='row flash-offer-content-area'>

                  <div className='col-sm-12' style={{ padding: '10px' }}>
                    <h5 className='flash-offer-content-title'>Yeni bir Flash Teklif başlat ⚡️</h5>
                    <p className='flash-offer-content-paragraph'>
                      Müşterilerinizi cazip bir teklifle artırın. Şimdi Bir Alana Bir Bedava kampanyası başlatın ve işinizi canlandırın!
                    </p>
                    <Button disabled={activeDeal} className='flash-offer-button' onClick={openNewFlashOffer}>
                      <img src={arrow} /> Yeni Flash Teklif Başlat
                    </Button>
                  </div>
                </div>
              )
              }


            </div>
          )}

        </div>
      </div>
    </div >
  )
}
