import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/LoginStyles.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import { login } from '../../api/auth';

import { cdnBaseUrl } from '../../api/baseUrl';
import {routeForgotPassword, routeHome, routeRegister, routeSuperAdmin, RoutingHelper} from '../../constants/routes';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { MdOutlineEmail, MdOutlineKey, MdOutlinePassword } from 'react-icons/md'
import { Repository } from '../../data/repository/repository';
import { LoginRequest } from "../../model/request/LoginRequest";

import { DocumentDTO } from '@/model/dto/DocumentDTO';


const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const yerimLogo = require('../images/yerimLogo.png')
  const bg = require('../images/Pattern 2.png')
  const navigate = useNavigate();
  const location = useLocation();
  const arrow = require('../images/icons/Text--new-line.png')
  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [documentAboutUsUrl, setDocumentAboutUsUrl] = useState<string>('');
  const [documentPrivacyUrl, setDocumentPrivacyUrl] = useState<string>('');
  const [documentTermsAndConditionsUrl, setDocumentTermsAndConditionsUrl] = useState<string>('');


  const emailIcon = require('../images/icons/Email1.png')
  const passIcon = require('../images/icons/Password.png')


  useEffect(() => {
    if(Repository.hasValidSession()) {
      RoutingHelper.navigateByUrl(routeHome)
      return
    }

    (async () => { setDocuments(await Repository.getAppDocuments() ?? []) })();

  }, []);
  useEffect(() => {
    if (documents !== null && documents.length > 0) {
      setDocumentTermsAndConditionsUrl(getDocumentUrlByCode('LO1'));
      setDocumentAboutUsUrl(getDocumentUrlByCode('LO0'));
      setDocumentPrivacyUrl(getDocumentUrlByCode('LO3'));
    }
  }, [documents]);

  /*
  const cacheDocuments = async () => 
  {
    const expireTime = 1800000;//half hour miliseconds

    const documentsData = await Repository.getAppDocuments();

    if(documentsData === null)
    {
        return;
    }

    setDocuments(documentsData);

    localStorage.setItem('documents', JSON.stringify(
      {
        data: documentsData,
        expiry: new Date().getTime() + expireTime
      }
    ));
  }
*/
  const getDocumentUrlByCode = (code: string) => {
    try {
      var document = documents.filter(d => d.code === code)[0];

      return cdnBaseUrl + document.documentUrl;
    }
    catch (error) {
      return "";
    }
  }

  const mail = require('../images/icons/Email.png')

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    try {
      // const { success, message, role } = await login(email, password);
      const response = await Repository.login(new LoginRequest(email,password))

      if (response) {
        const role = response.company.userRole
        if (role === 3) {
          navigate(routeHome);
        } else if (role === 0) {
          navigate(routeSuperAdmin);
        } else {
          setError('Giriş Esnasında Bir Hata Meydana Geldi');
        }
      } else {
        setError('E-mail ya da șifre yanlıș!');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Giriş sırasında bir hata oluştu');
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const firstChar = value.charAt(0).replace('İ','i').toLowerCase()
    setEmail(firstChar + value.slice(1));
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 background-image"></div>
        <div className="col-md-6 loginRight">
          <div className='col-md-6 login-form-container'>
            <div className='logoBackgroundDiv'>
              <img className='yerimLogo' src={yerimLogo} alt='Yerim Logo' />
            </div>
            <div className='loginHeader'>
              <h4 className='headerTitle'>Yerim’e tekrar Hoş Geldiniz</h4>

              <p className='headerParagraph'>Kayıtlı üyeler, lütfen giriş yapın. Eğer hesabınız yoksa, başvuru için info@yerimapp.com adresine mail atabilirsiniz.</p>
              {/* <a className='headerLink' href={routeRegister}>Kayıt Ol</a> */}
            </div>


            <form onSubmit={handleLogin}>
              <div className="form-group">
                <div style={{marginBottom:'25px'}} className="input-group input-with-icon">
                  <TextField
                    type="text"
                    id="email"
                    autoCapitalize='none'
                    className="form-control login-email"
                    placeholder="E-Mail Adresi"
                    label="E-Mail Adresi"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                      <MdOutlineEmail style={{color:'#1D1E2599'}}/>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <div  className="input-group input-with-icon">
                  {/* <div className="input-group-prepend">
                    <span className="">
                      <FontAwesomeIcon className='keyIcon' icon={faKey} />
                    </span>
                  </div> */}
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    className="form-control input-with-icon  login-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Şifre"
                    label='Şifre'
                    autoCapitalize='none'
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <img src={passIcon}/>

                      )
                    }}
                  />

                </div>
              </div>
              <div className='forgotPasswordDiv'>
                <p>{error}</p>
                <a href={routeForgotPassword} className='forgotPassword'>Şifremi Unuttum</a>
              </div>
              <button type="submit" className="btn btn-primary btn-block signIn"><img style={{ marginRight: '5px' }} src={arrow} />Giriş Yap</button>
            </form>

            

          </div>
          <div className='links-container'>
            <div style={{marginRight: '0px', marginTop: '75px'}} className='Links'>
              <a target='_blank' href={documentAboutUsUrl} className='link'>Hakkımızda</a>
              <a target='_blank' href={documentTermsAndConditionsUrl} className='link'>Şartlar ve Koşullar</a>
              <a target='_blank' href={documentPrivacyUrl} className='link'>Gizlilik Politikası</a>
              <text style={{marginRight: '0px', marginLeft: '75px'}} className={'appVersion'}>v1.3.17</text>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Login;
