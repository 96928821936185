import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import StopCampaignSessionPopup from '../popup/StopCampaignSessionPopup';
import { CampaignDTO } from '../../model/dto/CampaignDTO';
import { Repository } from '../../data/repository/repository';
import moment from 'moment';
import { TimeHelper } from '../../helpers/TimeHelper';

export default function FutureCampaignScreen() {
    const [showStopSessionModal, setShowStopSessionModal] = useState<boolean>(false);
    const [futureCampaigns, setFutureCampaigns] = useState<CampaignDTO[]>([])
    const leftColumnBackground = require('../images/weeklyplan.png');
    const [activeTab, setActiveTab] = useState('GünlükPlan');
    const lock = require('../images/icons/Locked.png');
    const stop = require('../images/icons/stop.png')

    const getBackgroundStyle = () => {
        switch (activeTab) {
            case 'GünlükPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '90vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            case 'HaftalıkPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '200vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            default:
                return {};
        }
    };

    const handleStopSessionClick = () => {
        setShowStopSessionModal(true);
    };
    const handleCloseStopSessionModal = () => {
        setShowStopSessionModal(false);
    };

    const fetchCampaignsData = async () => {
        try {
            const campaigns = await Repository.fetchMyCampaigns();
            const hasActiveAutomation = await Repository.getActiveAutomation(Repository.getMyCompany()?.id ?? '');
            if (Array.isArray(campaigns)) {

                const futureCampaigns = campaigns.filter((obj: CampaignDTO) => {
                    const startDate = moment(obj.startDate, "DD/MM/YYYY HH:mm:ss");
                    const finishDate = moment(obj.finishDate, "DD/MM/YYYY HH:mm:ss");
                    return startDate.isAfter(TimeHelper.now()) && obj.promotionType === 0 && finishDate.isAfter(TimeHelper.now());
                });
                setFutureCampaigns(futureCampaigns)

            }
        } catch (error) {
            console.error(error);
        };
    }

    useEffect(() => {
        fetchCampaignsData()
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-2">
                    <div className='backgroundDiv'>
                        <img style={getBackgroundStyle()} src={leftColumnBackground} alt="background" />
                    </div>
                </div>
                <div className="col-sm-10">
                    <div style={{ padding: '30px', height: ' calc(100vh - 280px)', marginLeft: '20px' }}>
                        <div className='titleLiveRightNov'>
                            <div>
                                <h2 style={{ marginTop: '20px', marginLeft: '-20px', fontWeight: '600', fontSize: '36px', lineHeight: '46px' }}>
                                    Bekleniyor...
                                </h2>
                            </div>
                            <hr style={{ width: '100%', marginLeft: '-20px', marginTop: '24px', marginBottom: '40px' }} />
                        </div>
                        <div className='row upperContent'>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: '60px' }}>
                                    <p style={{ fontWeight: '200', fontSize: '16px', lineHeight: '26px', marginBottom: '12px' }}>Ne zaman açılacak?</p>
                                    <button style={{ border: 'none', width: '230px', fontSize: '40px', lineHeight: '50px', backgroundColor: 'transparent', padding: '0px', marginLeft: '-40px' }} ><img src={lock} style={{ width: '35px', marginBottom: '10px', marginRight: '5px' }} /> {futureCampaigns.length > 0
                                        ? moment(futureCampaigns[0].startDate, "DD/MM/YYYY HH:mm:ss").format('HH:mm')
                                        : 'Belirlenmedi'}</button>
                                </div>
                                <div
                                    style={{
                                        height: '100px',
                                        width: '1px',
                                        backgroundColor: '#1D1E2533',
                                        marginRight: '60px'
                                    }}
                                ></div>

                                <div>
                                    <p style={{ fontWeight: '200', fontSize: '16px', lineHeight: '26px', marginBottom: '12px' }}>
                                        Ne zaman kapansın?
                                    </p>
                                    <button style={{ border: 'none', width: '230px', fontSize: '40px', lineHeight: '50px', backgroundColor: 'transparent', padding: '0px', marginLeft: '-45px' }}>

                                        <img src={lock} style={{ width: '35px', marginBottom: '10px', marginRight: '5px' }} />  {futureCampaigns.length > 0
                                            ? moment(futureCampaigns[0].finishDate, "DD/MM/YYYY HH:mm:ss").format('HH:mm')
                                            : 'Belirlenmedi'}
                                    </button>

                                </div>
                            </div>

                            <div>
                                <p style={{ fontWeight: '200', fontSize: '16px', lineHeight: '26px', marginBottom: '12px' }}>
                                    İndirim oranı
                                </p>
                                <button style={{ border: 'none', width: '250px', fontSize: '40px', lineHeight: '50px', backgroundColor: 'transparent', padding: '0px', marginLeft: '-35px' }}>

                                    <img src={lock} style={{ width: '35px', marginBottom: '10px', marginRight: '5px' }} />   % {futureCampaigns.length > 0
                                        ? futureCampaigns[0].discountRate
                                        : 'Belirlenmedi'} 🤩
                                </button>

                            </div>
                            <hr style={{ width: '100%', marginLeft: '-20px', marginTop: '40px', marginBottom: '40px' }} />

                            <div>
                                <button onClick={handleStopSessionClick} style={{ border: '1px solid #1D1E2533', fontSize: '16px', fontWeight: '600' }}><img src={stop} style={{ marginRight: '5px' }} />İptal Et</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='stop-session-container'>
                <Modal
                    show={showStopSessionModal}
                    onHide={handleCloseStopSessionModal}
                    style={{ height: '95vh' }}
                >
                    <StopCampaignSessionPopup onClose={handleCloseStopSessionModal} automation = {false} />
                </Modal>
            </div>
        </div>
    )
}
