import React, { useEffect, useState } from 'react'
import '../styles/style.css'
import { AiOutlineClose } from 'react-icons/ai';
import { Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CampaignDTO } from '@/model/dto/CampaignDTO';
import moment, { now } from 'moment';
import baseUrl from '../../api/baseUrl';
import { cdnBaseUrl } from '../../api/baseUrl';
import { Repository } from '../../data/repository/repository';
import { DocumentDTO } from '@/model/dto/DocumentDTO';


interface Props {
    onClose: () => void;
    campaignId:string | undefined;
}

export default function CancelAutomation({ onClose,campaignId }: Props) {
    const warning = require('../images/icons/Warning--hex.png');
    const arrow = require('../images/icons/Text--new-line.png')
    const navigate = useNavigate();



    const handleStopSession = async () => {
        try {
            const success = await Repository.cancelAutomation(campaignId ?? '');
            if (success) {
                toast.success('Kampanya Durduruldu!');
                onClose();
                window.location.reload()
            } else {
                toast.error('Kampanya Durdurma Başarısız!');
            }
        } catch (error) {
            toast.error('Bir Hata Meydana Geldi!');
        }
    };


    return (
        <div className='custom-overlay'>
        <div className="popup-content" style={{height:'unset'}}>
            <div className='stopSession'>
                <img src={warning} style={{ width: '100px' }} />
                <button onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Vazgeç</button>
            </div>
            <div className='content'>
                <h5>Oturumunuzu Durdurmak Üzeresiniz!</h5>
                <p className='stopSessionFirstP'>Şu anda haftalık bir planı kapatmak üzere olduğunuzu hatırlatırız.</p>
                
            </div>
            <hr style={{ width: '100%', marginBottom: '50px' }} />
            <div>
                <h5 className='rUSure'>Oturumu kapatmak istediğinizden emin misiniz?</h5>
            </div>
           
            <div className='confirmButtons'>
                <button onClick={onClose} className='confirmButtonNo'><img src={arrow} /> Hayır</button>
                <button onClick={handleStopSession} className='confirmButtonYes'> Evet</button>

            </div>
        </div>
        </div>
    )
}
